import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {User} from '../../model/user';
import {UserService} from '../../service/user.service';
import {ResponseServer} from '../../model/response_server';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.css'],
})
export class UserComponent extends BaseComponent implements OnInit {

    listUser: User[] = [];
    model: User = new User();
    passwordConfirm: string;
    pager: any = {};

    constructor(public router: Router,
                public service: UserService,
                public loading: NgxSpinnerService) {
        super(router, loading);
    }

    ngOnInit() {
        this.getList('');
    }

    onSearch(search: string) {
        this.getList(search);
    }

    getList(search: string) {
        this.loading.show();
        this.service.getUsers(search).subscribe(
            data => {
                this.loading.hide();
                this.listUser = data as User[];
                this.setPage(1);
            },
            error => super.onError(error));
    }

    onSubmitSave() {
        this.loading.show();

        if (this.model.id !== undefined) {
            this.onUpdate(this.model);
            return;
        }

        this.service.createEmployee(this.model).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                }
                this.getList('');
                super.onCloseModal('#basic');
            },
            error => super.onError(error));
    }

    onUpdate(model: User) {
        this.service.updateEmployee(model).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    super.onCloseModal('#basic');
                    this.getList('');
                }

            },
            error => super.onError(error));
    }

    remove(id: string) {
        this.loading.show();
        this.service.deleteEmployee(id).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.getList('');
                }
            },
            error => super.onError(error));
    }

    onRemove(id: string) {
        const self = this;
        super.showConfirmMessage('Atenção',
            'Tem certeza que deseja remover esse usuário?',
            'warning', () => {
                self.remove(id);
            });
    }

    onItemclick() {
        super.onShowModal('#basic');
    }

    setPage(page: number) {
        this.pager = this.getPager(this.listUser.length, page, 5);
    }

}
