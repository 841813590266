export class Plan {
    
    id: string;
    user: string;
    establishment: string;
    promocode: string;

    name: string;
    description: string;
    orders: number;
    price: string;
    type_payment: string;

    constructor(){
        this.id = '0';
    }
    
}