import {Establishment} from './establishment';

export class User {

    id: string;
    name: string;
    lastname: string;
    email: string;
    password: string;
    type = 'establishment';
    created_at: string;
    updated_at: string;
    token: string;
    establishment: Establishment;
    establishment_id: string;
    hash: string;
    photo: string;
    ddd: string;
    phone: string;

    constructor() {
    }

}
