import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {UserService} from '../../service/user.service';
import {ResponseServer} from '../../model/response_server';
import {User} from '../../model/user';
import {Settings} from '../../settings';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [UserService]
})
export class LoginComponent extends BaseComponent implements OnInit {

  isForgot = false;

  model: User = new User();

  version: string;

  constructor(public router: Router,
              public service: UserService,
              public loading: NgxSpinnerService) {
      super(router, loading);
  }

  ngOnInit() {
    this.version = Settings.VERSION;
    const body = document.getElementsByTagName('body')[0];
     body.classList.add('login');
     body.classList.add('background-login');
  }

  onLogin(email: string, password: string) {
      email = email.trim();
      password = password.trim();
    if (email.length === 0 && password.length === 0) {
      // success,warning,error,info
      super.showMessage('Atenção', 'Informe o Email e Senha.', 'info');
      return;
    }

    this.loading.show();
    this.service.login(email, password, 'establishment').subscribe(
      data => {
          const result = data as ResponseServer;
          if (result.error) {
              super.showMessage('Atenção', result.message, 'info');
          } else {
              this.model = data as User;
              if (this.model.establishment['status'] === 'inactive' || this.model.establishment['status'] === 'bloqued') {
                  super.showMessage('Atenção',
                      'O seu estabelecimento ainda não está aprovado ou está bloqueado. Favor entrar em contato com a central.',
                      'error');
              } else {
                  localStorage.setItem('token', this.model.token);
                  super.setUser(this.model);
                  this.router.navigate(['/home']);
              }
          }

        this.loading.hide();

      },
      error => this.onError(error)

    );


  }

  onForgot(email: string) {
    if (email.length === 0) {
      super.showMessage('Atenção', 'Informe o email .', 'info');
      return;
    }
    const request = {email: email, type: 'establishment'};
    this.loading.show();
    this.service.forgot(request).subscribe(
      data => {
        const result = data as ResponseServer;
        this.loading.hide();
        if (result.error) {
        } else {
          super.showMessage('Atenção', result.message, 'info');
        }
        return true;
      },
      error => this.onError(error)
    );
  }

}
