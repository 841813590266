import {Component, OnInit} from '@angular/core';
import {User} from '../../model/user';
import {ResponseServer} from '../../model/response_server';
import {Router} from '@angular/router';
import {BaseComponent} from '../../base/base.component';
import {UserService} from '../../service/user.service';
import {MercadopagoService} from '../../service/mercadopago.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-register-email',
    templateUrl: './register-email.component.html',
    styleUrls: ['./register-email.component.css'],
    providers: [UserService, MercadopagoService]
})
export class RegisterEmailComponent extends BaseComponent implements OnInit {


    model: User = new User();

    password_confirm: '';
    link: string;

    count = 0;

    constructor(public router: Router,
                public service: UserService,
                public servicemp: MercadopagoService,
                public loading: NgxSpinnerService) {
        super(router, loading);
    }

    ngOnInit() {
    }

    onSave() {
        this.model.establishment_id = super.getUser().establishment.id;
        this.loading.show();
        this.service.createEmployee(this.model).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.model = new User();
                    this.count = data['count'];
                    this.servicemp.mercadopago().subscribe(
                        data2 => {
                            this.link = data2['link'];
                            window.location.href = (this.link);
                        }
                    );
                }
            },
            error => super.onError(error));
    }

    onChange() {
         this.router.navigate(['/bemvindo']);
    }


}
