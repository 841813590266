import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.css']
})
export class TipsComponent extends BaseComponent implements OnInit {

  flag: number;

  constructor(public loading: NgxSpinnerService, public router: Router) {
      super(router, loading);
  }

  ngOnInit() {
    this.flag = 1;
  }

    changeTab(tab) {
        this.flag = tab;
    }

}
