import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {EstablishmentService} from '../../service/establishment.service';
import {Establishment} from '../../model/establishment';
import {ResponseServer} from '../../model/response_server';
import {BaseComponent} from '../../base/base.component';
import {isNullOrUndefined} from 'util';
import {UploadService} from '../../service/upload.service';
import {NgxSpinnerService} from 'ngx-spinner';


@Component({
    selector: 'app-establishment',
    templateUrl: './establishment.component.html',
    styleUrls: ['./establishment.component.css'],
    providers: [EstablishmentService, UploadService]
})
export class EstablishmentComponent extends BaseComponent implements OnInit {

    _thumpPath = 'assets/pages/img/nophoto.png';
    _previewName = '';
    changeDocument = '';
    file: File = null;
    initmap = false;
    shareUrl: string;

    listEstablishment: Establishment[] = [];
    model: Establishment = new Establishment();

    constructor(public router: Router,
                public service: EstablishmentService,
                public loading: NgxSpinnerService,
                public uploadservice: UploadService) {
        super(router, loading);
    }

    ngOnInit() {
        this.get();
    }

    get() {
        this.loading.show();
        this.service.getEstablishment().subscribe(
            data => {
                this.model = data as Establishment;
                if (this.model.photo.length !== 0) {
                    this._thumpPath = this.model.photo;
                    const user = super.getUser();
                    user.establishment.photo = this.model.photo;
                    super.setUser(user);
                    console.log(this.loggedUser);
                }
                this.loading.hide();
            },
            error => super.onError(error)
        );
    }

    generateShareLink(id: string) {
        this.loading.show();
        this.service.generateShareLink(id).subscribe(
            data => {
                this.shareUrl = data['shortLink'];
                this.loading.hide();
                this.onShowModal('#shareUrl');
            },
            error => super.onError(error)
        );
    }

    copyShareLink() {
        const copyText = document.getElementById('shareInput') as HTMLInputElement;
        copyText.select();
        document.execCommand('copy');
        alert('Copiado!');
    }

    onSave() {
        if (isNullOrUndefined(this.model.reference)) {
            this.model.reference = '';
        }
        if (isNullOrUndefined(this.model.estimated_time)) {
            super.showMessage(
                'Atenção',
                'É necessário que a estimativa de entrega seja definida', 'info');
        }

        const self = this;
        super.showConfirmMessage('Salvar dados',
            'Tem certeza que atualizar essas informações?', 'warning', () => {
                self.save();
                self.loading.show();
            });
    }

    save() {
        this.service.update(this.model).subscribe(
            data => {
                const result = data as ResponseServer;
                if (result.error) {
                    this.loading.hide();
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    if (!isNullOrUndefined(this.file)) {
                        this.uploadImage();
                    } else {
                        this.get();
                    }
                }

            },
            error => super.onError(error));
    }

    handleUpload(event) {
        // console.log('onChange');
        const files = event.srcElement.files;
        if (files.length > 0) {
            if (files[0].size > 2000000) {
                super.showMessage('Atenção',
                    'A foto não deve ultrapassar o tamanho de 2MB', 'info');
                return;
            }

            if (files[0].type.indexOf('jpeg') === -1 && files[0].type.indexOf('png') === -1) {
                super.showMessage('Atenção',
                    'Só será permitida fotos com extensões .jpg ou .png', 'info');
                return;
            }

        }
        const reader = new FileReader();
        reader.onload = (_event) => {
            this._thumpPath = _event.target['result'];
        };
        reader.readAsDataURL(event.target.files[0]);

        this.file = files[0];
    }

    uploadImage() {
        this.uploadservice.uploadEstablishment(this.file).subscribe(
            data => {
                const result = data as ResponseServer;
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.get();
                }
            },
            error => super.onError(error));
    }

    initMap() {
        this.initmap = true;
    }
}
