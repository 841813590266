import {AttendenceItem} from "./attendence-item";

export class Attendence {

  auto_open: number;
  items: AttendenceItem[];

  constructor() {
    this.auto_open = 0;
  }
}
