import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {PlanService} from '../../service/plan.service';
import {ResponseServer} from '../../model/response_server';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-ticket',
    templateUrl: './ticket.component.html',
    styleUrls: ['./ticket.component.css'],
    providers: [PlanService]
})
export class TicketComponent extends BaseComponent implements OnInit {

    planId = {plan_id: ''};

    constructor(public router: Router,
                public route2: ActivatedRoute,
                public service: PlanService,
                public loading: NgxSpinnerService) {
        super(router, loading);
    }

    ngOnInit() {
        this.route2.params.subscribe(data => {
            const resulID = data['hash'];
            if (resulID) {
                this.planId.plan_id = resulID;
            }
        });
    }

    onSave() {
        this.loading.show();
        this.service.chooseBoleto(this.planId).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'error');
                } else {
                    this.router.navigate(['/home/']);
                }
            },
            error => super.onError(error));
    }

}
