import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {Plan} from '../../model/plan';
import {PlanService} from '../../service/plan.service';
import {isNullOrUndefined} from 'util';
import {MercadopagoService} from '../../service/mercadopago.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-plan',
    templateUrl: './plan.component.html',
    styleUrls: ['./plan.component.css'],
    providers: [PlanService]
})
export class PlanComponent extends BaseComponent implements OnInit {

    model: Plan = new Plan();
    listPlan: Plan[] = [];
    link: string;
    divId = '';
    divIdfooter = '';
    index: number;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public service: PlanService) {
        super(router, loading);
    }

    ngOnInit() {
        this.getList();
    }

    getList() {
        this.service.getPlan().subscribe(
            data => {
                this.listPlan = data as Plan[];
            },
            error => super.onError(error));
    }

    onChoose(plan) {
        this.model = new Plan();
        this.model = plan;
        this.divId = '';
        this.divIdfooter = '';
        for (const item of this.listPlan) {
            if (item.id === plan.id) {
                this.index = this.listPlan.indexOf(this.model);
                this.divId = 'plan' + this.index;
                this.divIdfooter = this.divId + 'footer';
                const divPlan = document.getElementById(this.divId);
                const divPlanFooter = document.getElementById(this.divIdfooter);
                divPlan.style.background = 'rgba(159, 163, 167, 0.5)';
                divPlanFooter.style.background = 'rgba(159, 163, 167, 0.5)';

            } else if (item.id !== plan.id) {
                this.index = this.listPlan.indexOf(item);
                this.divId = 'plan' + this.index;
                this.divIdfooter = this.divId + 'footer';
                const divPlan = document.getElementById(this.divId);
                const divPlanFooter = document.getElementById(this.divIdfooter);
                divPlan.style.background = 'rgba(255, 255, 255, 1)';
                divPlanFooter.style.background = 'rgba(255, 255, 255, 1)';
            }
        }

    }

    onSave() {
        if (isNullOrUndefined(this.index) || isNullOrUndefined(this.model.id)) {
            super.showMessage('Atenção', 'Selecione um Plano', 'info');
            return;
        }
        this.router.navigate(['/pagamento/', this.model.id]);
    }

    home() {
        this.router.navigate(['/home/']);
    }

}
