import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AttendenceService} from '../../service/attendence.service';
import {Attendence} from '../../model/attendence';
import {ResponseServer} from '../../model/response_server';
import {BaseComponent} from '../../base/base.component';
import {AttendenceItem} from '../../model/attendence-item';
import {NgxSpinnerService} from 'ngx-spinner';
import {isNullOrUndefined} from "util";

@Component({
    selector: 'app-attendence',
    templateUrl: './attendence.component.html',
    styleUrls: ['./attendence.component.css'],
    providers: [AttendenceService]
})
export class AttendenceComponent extends BaseComponent implements OnInit {

    attendence: Attendence = new Attendence();
    model: AttendenceItem = new AttendenceItem();
    // pager: any = {};

    listDays = [];

    state = {
        day: '',
        time: '',
        end: '',
    };

    weekdays = [
        'Domingo',
        'Segunda-Feira',
        'Terça-Feira',
        'Quarta-Feira',
        'Quinta-Feira',
        'Sexta-Feira',
        'Sábado'
    ];

    times = [
        '00:00',
        '00:15',
        '00:30',
        '00:45',
        '01:00',
        '01:15',
        '01:30',
        '01:45',
        '02:00',
        '02:15',
        '02:30',
        '02:45',
        '03:00',
        '03:15',
        '03:30',
        '03:45',
        '04:00',
        '04:15',
        '04:30',
        '04:45',
        '05:00',
        '05:15',
        '05:30',
        '05:45',
        '06:00',
        '06:15',
        '06:30',
        '06:45',
        '07:00',
        '07:15',
        '07:30',
        '07:45',
        '08:00',
        '08:15',
        '08:30',
        '08:45',
        '09:00',
        '09:15',
        '09:30',
        '09:45',
        '10:00',
        '10:15',
        '10:30',
        '10:45',
        '11:00',
        '11:15',
        '11:30',
        '11:45',
        '12:00',
        '12:15',
        '12:30',
        '12:45',
        '13:00',
        '13:15',
        '13:30',
        '13:45',
        '14:00',
        '14:15',
        '14:30',
        '14:45',
        '15:00',
        '15:15',
        '15:30',
        '15:45',
        '16:00',
        '16:15',
        '16:30',
        '16:45',
        '17:00',
        '17:15',
        '17:30',
        '17:45',
        '18:00',
        '18:15',
        '18:30',
        '18:45',
        '19:00',
        '19:15',
        '19:30',
        '19:45',
        '20:00',
        '20:15',
        '20:30',
        '20:45',
        '21:00',
        '21:15',
        '21:30',
        '21:45',
        '22:00',
        '22:15',
        '22:30',
        '22:45',
        '23:00',
        '23:15',
        '23:30',
        '23:45'
    ];

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public service: AttendenceService) {
        super(router, loading);
    }

    ngOnInit() {
        this.getList();
        this.getListDays();
    }

    getList() {
        this.loading.show();
        this.service.getAttendences().subscribe(
            data => {
                this.loading.hide();
                this.attendence = data as Attendence;
                // this.setPage(1);
            },
            error => super.onError(error));
    }

    getListDays() {
        this.loading.show();
        this.service.getDays().subscribe(
            data => {
                this.loading.hide();
                this.listDays = data as any[];

            },
            error => super.onError(error));
    }

    /*setAutoOpen() {
        this.service.setAutoOpen().subscribe(data => {
            this.attendence.auto_open = data['auto_open'];
            return;
        }, error => super.onError(error));
        this.attendence.auto_open = 0;
    }*/

    onSubmitSave() {
        if (this.stringTimeToMinutes(this.model.time_end) <= this.stringTimeToMinutes(this.model.time_start)) {
            super.showMessage('Atenção', 'A hora de Fechamento deve der maior que a hora de Abertura');
            return;
        }

        if (typeof this.model.attendance_day_id === 'undefined') {
            super.showMessage('Atenção', 'Selecione o Dia da Semana');
            return;
        }

        if (typeof this.model.time_start === 'undefined') {
            super.showMessage('Atenção', 'Insira o Horário de Abertura');
            return;
        }

        if (typeof this.model.time_end === 'undefined') {
            super.showMessage('Atenção', 'Insira o Horário de Fechamento');
            return;
        }

        this.loading.show();

        this.service.create(this.model).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.model = new AttendenceItem();
                    this.getList();
                }
            },
            error => super.onError(error));
    }

    remove(id: string) {
        this.loading.show();
        this.service.delete(id).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.getList();
                }
            },
            error => super.onError(error));
    }

    onRemove(id: string) {
        const self = this;
        super.showConfirmMessage('Atenção',
            'Tem certeza que deseja remover este horário?',
            'warning', () => {
                self.remove(id);
            });
    }

    filterAtt(day: string) {
        if (isNullOrUndefined(this.attendence) || isNullOrUndefined(this.attendence.items)) {
            return [];
        }
        return this.attendence.items.filter(function (val) {
            return val.attendance === day;
        });
    }

    stringTimeToMinutes(time: string) {
        const m = Number(time.substring(3));
        const h = Number(time.substring(0, 2));
        return m + (h * 60);
    }

    /*setPage(page: number) {
        this.pager = this.getPager(this.attendence.items.length, page, 5);
    }*/
}
