import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Printer} from "../model/printer";

@Injectable()
export class PrinterService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getPrinter() {
        const url = `${this.BASE_URL}/api/printer/get`;
        return this.http.get(url);
    }

    delete(id: string) {
        const url = `${this.BASE_URL}/api/printer/remove/${id}`;
        return this.http.delete(url);
    }

    create(model: Printer) {
        const url = `${this.BASE_URL}/api/printer/put`;
        return this.http.put(url, model);
    }
}
