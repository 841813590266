import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tips-financial',
  templateUrl: './tips-financial.component.html',
  styleUrls: ['./tips-financial.component.css']
})
export class TipsFinancialComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
