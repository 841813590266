import {ServiceBase} from './service-base';
import {Injectable} from '@angular/core';
import {Attendence} from '../model/attendence';
import {HttpClient} from '@angular/common/http';
import {AttendenceItem} from "../model/attendence-item";
/**
 * Created by Igor on 09/05/2017.
 */
@Injectable()
export class AttendenceService extends ServiceBase {

  constructor(http: HttpClient) {
    super(http);
  }

    getDays() {
        const url = `${this.BASE_URL}/api/establishment/attendance/listdays`;
        return this.http.get(url);
    }

  getAttendences() {
    const url = `${this.BASE_URL}/api/establishment/attendance/list`;
    return this.http.get(url);
  }

  create(model: AttendenceItem) {
    const url = `${this.BASE_URL}/api/establishment/attendance/add`;
    return this.http
      .post(url, model);
  }

  delete(id: string) {
    const url = `${this.BASE_URL}/api/establishment/attendance/remove/${id}`;
    return this.http.delete(url);
  }

  setAutoOpen() {
    const url = `${this.BASE_URL}/api/establishment/update/auto/open`;
      return this.http
          .post(url, null);
  }

}
