import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {EstablishmentCategory} from '../model/establishment-category';
import {HttpClient} from '@angular/common/http';
/**
 * Created by Igor on 16/06/2017.
 */
@Injectable()
export class EstablishmentCategoryService extends ServiceBase {

  constructor(http: HttpClient) {
    super(http);
  }

  getCategory() {
    const url = `${this.BASE_URL}/api/establishment/category/list`;
    return this.http.get(url);
  }

  create(model: EstablishmentCategory) {
    const url = `${this.BASE_URL}/api/establishment/category/add`;
    return this.http
      .post(url, model);
  }

  update(model: EstablishmentCategory) {
    const url = `${this.BASE_URL}/api/establishment/category/update`;
    return this.http
      .post(url, model);
  }

  delete(hash: string) {
    const url = `${this.BASE_URL}/api/establishment/category/remove/${hash}`;
    return this.http.delete(url);
  }

  status(hash: string) {
    const url = `${this.BASE_URL}/api/establishment/category/updatestatus/${hash}`;
    return this.http.get(url);
  }

}
