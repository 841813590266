import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {GraphService} from '../../service/graph.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-graph-delivery',
  templateUrl: './graph-delivery.component.html',
  styleUrls: ['./graph-delivery.component.css'],
    providers: [GraphService]
})
export class GraphDeliveryComponent extends BaseComponent implements OnInit {

    requestDaily = {
        month: '11',
        year: '2018'
    };

    requestMonth = {
        month: '08',
        year: '2019'
    };

  constructor(public router: Router,
              public service: GraphService,
              public loading: NgxSpinnerService) {
      super(router, loading);
  }

  ngOnInit() {
    this.getChartDeliveryDay();
    this.getChartDeliveryZone();
  }

    public getChartDeliveryDay() {
        this.loading.show();
        this.service.getChartDeliveryDay().subscribe(
            data => {
                this.loading.hide();
                Chart.chartDeliveryDay(data);
            },
            error => super.onError(error));
    }

    public getChartDeliveryZone() {
        this.loading.show();
        this.service.getChartDeliveryZone().subscribe(
            data => {
                this.loading.hide();
                Chart.chartDeliveryZone(data);
            },
            error => super.onError(error));
    }


}
