import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {ProductSize} from '../../model/product-size';
import {ProductSizeService} from '../../service/product-size.service';
import {isUndefined} from 'util';
import {ResponseServer} from '../../model/response_server';
import {NgxSpinnerService} from 'ngx-spinner';
import {ProductService} from '../../service/product.service';
import {EmitEvent, EventBusService, Events} from "../../service/event-bus.service";

@Component({
    selector: 'app-product-size',
    templateUrl: './product-size.component.html',
    styleUrls: ['./product-size.component.css'],
    providers: [ProductSizeService]
})
export class ProductSizeComponent extends BaseComponent implements OnChanges {

    prodHash = '';
    priceType = '';
    prodStatus = '';
    model: ProductSize = new ProductSize();
    listSize: ProductSize[] = [];
    pager: any = {};
    _editId = '';
    sizeInited = false;

    constructor(public router: Router,
                public service: ProductSizeService,
                public productService: ProductService,
                private eventbus: EventBusService,
                public loading: NgxSpinnerService) {
        super(router, loading);
    }

    @Output()
    saveProduct = new EventEmitter<string>();

    @Input()
    set priceTYPE(type: string) {
        if (type !== undefined) {
            this.priceType = type;
        }
    }

    @Input()
    set prodSTATUS(status: string) {
        if (status !== undefined) {
            this.prodStatus = status;
        }
    }

    @Input()
    set productID(id: string) {
        if (id !== undefined) {
            this.prodHash = id;
        }
    }

    @Input() initsize: boolean;

    ngOnChanges(changes: SimpleChanges) {

        for (const propName of Object.keys(changes)) {

            const change = changes[propName];
            const curVal = JSON.stringify(change.currentValue);

            if (curVal === 'true' && !this.sizeInited) {
                this.sizeInited = true;
                this.getList();
            }
        }
    }

    getList() {
        this.loading.show();
        this.service.getSizes(this.prodHash).subscribe(
            data => {
                this.loading.hide();
                this.listSize = data as ProductSize[];
                this.model.order = this.listSize.length;
                if (this.model.order === 0) {
                    this.model.description = 'Único';
                    this.model.initials = 'U';
                }
                this.eventbus.emit(new EmitEvent(Events.isComplement));
                this.setPage(1);
            },
            error => super.onError(error));
    }

    onSubmitSave() {
        if (this.prodHash.length === 0) {
            super.showMessage('Atenção', 'Salve o produto antes por favor', 'info');
            return;
        }

        if (isUndefined(this.model.description)) {
            super.showMessage('Atenção', 'Insira o Nome do Tamanho', 'info');
            return;
        }

        if (isUndefined(this.model.initials)) {
            super.showMessage('Atenção', 'Insira uma Sigla para o Tamanho', 'info');
            return;
        }

        if (this.priceType === 'fixed' && isUndefined(this.model.price)) {
            super.showMessage('Atenção', 'Insira um preço para o Tamanho', 'info');
            return;
        }

        if (this.priceType === 'fixed' && isUndefined(this.model.discount)) {
            super.showMessage('Atenção', 'Insira um desconto para o Tamanho', 'info');
            return;
        }

        if (this.priceType === 'fixed' && (this.model.discount < 0 || this.model.discount > 100)) {
            super.showMessage('Atenção', 'O desconto não pode ser maior que 100%', 'info');
            return;
        }

        if (isUndefined(this.model.order)) {
            super.showMessage('Atenção', 'Insira uma ordem para o Tamanho', 'info');
            return;
        }

        this.loading.show();
        this.model.establishment_product_id = this.prodHash;
        this.model.initials = this.model.initials.toUpperCase();
        this.service.create(this.model).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                }
                this.model = new ProductSize();
                this.getList();
                this.productService.productDataSource.next(false);
            },
            error => super.onError(error));
    }

    onRemove(hash: string) {
        const self = this;
        super.showConfirmMessage('Atenção',
            'Tem certeza que deseja remover esta opção? Os dados referente a esse tamanho serão removido',
            'warning', () => {
                self.remove(hash);
            });
    }

    remove(id: string) {
        this.loading.show();
        this.service.remove(id).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.getList();
                }
            },
            error => super.onError(error));
    }

    onEditRow(hash: string) {
        this._editId = hash;
    }

    onSaveRow(size: ProductSize) {
        this._editId = '';
        size.establishment_product_id = this.prodHash;
        size.initials = size.initials.toUpperCase();
        this.loading.show();
        this.service.update(size).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', 'Erro ao Atualizar o Tamanho', 'info');
                } else {
                    this.getList();
                }
            },
            error => super.onError(error));
    }

    Cancelar() {
        this._editId = '';
    }

    setPage(page: number) {
        this.pager = this.getPager(this.listSize.length, page, 10);
    }

    onChange() {
        this.saveProduct.emit(this.priceType);
    }

    calcDiscount(direction: boolean) {
        if (direction) {
            this.model.discountMoney =
                this.model.discount > 0 && this.model.discount < 101
                    ? this.model.price * (this.model.discount / 100) : 0;
        } else {
            this.model.discount = this.model.price !== 0
                ? (this.model.discountMoney / this.model.price) * 100 : 0;
        }
    }
}
