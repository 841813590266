import {Component, OnInit} from '@angular/core';
import {Order} from "../../model/order";
import {BaseComponent} from "../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {isUndefined} from "util";
import {OrderService} from "../../service/order.service";
import {OrderComplements} from "../../model/order_complements";

@Component({
    selector: 'app-order-detail',
    templateUrl: './order-detail.component.html',
    styleUrls: ['./order-detail.component.css'],
    providers: [OrderService]
})
export class OrderDetailComponent extends BaseComponent implements OnInit {

    model: Order = new Order();

    constructor(public route: ActivatedRoute,
                public service: OrderService,
                public router: Router,
                public loading: NgxSpinnerService) {
        super(router, loading);
        this.route.params.subscribe(p => {
            if (isUndefined(p['hash'])) {
            } else {
                this.model.id = p['hash'];
                this.getOrder();
            }
        });
    }

    ngOnInit() {
    }

    getOrder() {
        this.loading.show();
        this.service.getOrderById(this.model.id).subscribe(
            data => {
                this.model = data as Order;
                this.loading.hide();
            },
            error => super.onError(error));
    }

    verifyComplement(i: number, complements: OrderComplements[], order: number) {
        if (i === 0) {
            return true;
        }
        const atual = Math.trunc(complements[i - 1].order / 10);
        const next = Math.trunc(order / 10);
        if (atual !== next) {
            return true;
        }
    }

}
