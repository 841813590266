import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {Order} from '../../model/order';
import {User} from '../../model/user';
import {UserService} from '../../service/user.service';
import {OrderService} from '../../service/order.service';
import {Subscription} from 'rxjs/index';
import {ResponseServer} from '../../model/response_server';
import {OrderComplements} from '../../model/order_complements';
import {isNullOrUndefined} from 'util';
import {CancelOptions} from '../../model/cancel-options';
import {NgxSpinnerService} from 'ngx-spinner';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from '../../../environments/environment.prod';

declare var $: any;

@Component({
    selector: 'app-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.css'],
    providers: [OrderService, UserService]
})
export class OrderComponent extends BaseComponent implements OnInit {

    listDriver: User[] = [];
    listOrder: Order[] = [];

    cancelOptions: CancelOptions[] = [];
    selectedOption: string;

    hiddennew = false;
    hiddenpreparing = false;
    hiddendelivery = false;
    hiddendelivered = true;
    hiddencanceled = false;

    model: Order = new Order();
    pager: any = {};

    isAutoRefresh: boolean = false;

    request = {
        date_start: super.getDateNowNoFormat(-45),
        date_end: super.getDateNowNoFormat(+1),
        status: '',
        term: ''
    };

    sub: Subscription;
    urlTackOrder;

    constructor(public router: Router,
                public service: OrderService,
                public userservice: UserService,
                public loading: NgxSpinnerService,
                private sanitizer: DomSanitizer) {
        super(router, loading);
    }

    ngOnInit() {
        this.getCancelOptionsList();

        const parent = this;


        setInterval(function () {
            if (parent.isAutoRefresh) {
                parent.getList();
            }
        }, 30000);
    }


    onSearch() {
        this.loading.show();
        this.getList();
    }

    validationRequest() {
        if (this.request.date_start = '' || this.request.date_end) {
            super.showMessage('Atenção', 'Preencha as duas datas para pesquisar', 'info');
        }
    }

    getList() {
        this.service.getOrder(this.request).subscribe(
            data => {
                this.loading.hide();
                this.listOrder = data as Order[];

                this.setPage(1);
                return;

            },
            error => super.onError(error)
        );
    }

    getCancelOptionsList() {
        this.service.cancelOptionsList().subscribe(
            data => {
                this.cancelOptions = data as CancelOptions[];
                this.getList();
            },
            error => super.onError(error)
        );
    }

    printer(id) {
        this.service.printOrder(id).subscribe(
            data => {
                this.loading.hide();
                const result = data as ResponseServer;
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'error');
                } else {
                    this.getList();
                }

            },
            error => super.onError(error)
        );
    }

    hiddenStatus(status) {
        this.hiddennew = false;
        this.hiddenpreparing = false;
        this.hiddendelivery = false;
        this.hiddendelivered = true;
        this.hiddencanceled = false;
        switch (status) {
            case 'new':
                this.hiddennew = true;
                return;
            case 'preparing':
                this.hiddennew = true;
                this.hiddenpreparing = true;
                return;
            case 'delivery':
                this.hiddennew = true;
                this.hiddenpreparing = true;
                this.hiddendelivery = true;
                this.hiddendelivered = false;
                return;
            case 'delivered':
                this.hiddennew = true;
                this.hiddenpreparing = true;
                this.hiddendelivery = true;
                this.hiddendelivered = true;
                this.hiddencanceled = true;
                return;
            case 'canceled':
                this.hiddennew = true;
                this.hiddenpreparing = true;
                this.hiddendelivery = true;
                this.hiddendelivered = true;
                this.hiddencanceled = true;

                return;
        }
    }

    getStatus(order: Order, status, reason, saalveBag) {
        switch (order.status) {
            case 'new':
                return 'Novo';
            case 'preparing':
                return 'Em preparo';
            case 'desk':
                return 'Balcão';
            case 'delivery':
                if (!super.isNullOrUndefined(saalveBag)) {
                    return 'Acompanhar pedido';
                } else {
                    if (order.is_desk === 1) {
                        return 'Retirada no balcão';
                    }
                    return 'Saiu para Entrega';
                }
            case 'delivered':
                return 'Entregue';
            case 'canceled':
                return order.cancel_option_description;
        }
    }

    getStatusClass(order: Order) {
        switch (order.status) {
            case 'new':
                return 'label-success';
            case 'preparing':
                return 'label-primary';
            case 'delivery':
                if (order.is_desk === 1) {
                    return 'label-default';
                } else {
                    return 'label-warning';
                }
            case 'delivered':
                return 'label-info';
            case 'canceled':
                return 'label-danger';
            default:
                return '';

        }
    }

    getStatusIcon(order: Order) {
        switch (order.status) {
            case 'new':
                return 'fas fa-exclamation-triangle';
            case 'preparing':
                return 'fas fa-clock';
            case 'delivery':
                if (order.is_desk === 1) {
                    return 'fas fa-store';
                } else {
                    return 'fas fa-motorcycle';
                }
            case 'delivered':
                return 'fas fa-check';
            case 'canceled':
                return 'fas fa-ban';
            default:
                return '';

        }
    }

    onUpdate(order, status, noValidate = false) {
        Object.assign(this.model, order);
        this.model.status = status;
        const self = this;
        if (status === 'canceled') {
            this.onShowModal('#cancelReason');
            return;
        }
        if (noValidate) {
            this.onUpdateOrder();
            return;
        }
        super.showConfirmMessage('Atenção',
            'Tem certeza que deseja atualizar este pedido?',
            'warning', () => {
                self.onUpdateOrder();
            });
    }

    onSelectedReason() {
        if (isNullOrUndefined(this.selectedOption)) {
            return;
        }
        this.model.cancel_option_id = this.selectedOption;
        this.onCloseModal('#cancelReason');
        this.onUpdateOrder();
    }

    changeSelectedOption(option: string
    ) {
        this.selectedOption = option;
    }

    onUpdateOrder() {
        this.loading.show();
        this.service.updateOrder(this.model).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'error');
                } else {
                    this.getList();
                }
            },
            error => super.onError(error));
    }

    setPage(page
                :
                number
    ) {
        this.pager = this.getPager(this.listOrder.length, page, 20);
    }

    cancel() {
        this.onCloseModal('#cancelReason');
    }

    verifyComplement(i
                         :
                         number, complements
                         :
                         OrderComplements[], order
                         :
                         number
    ) {
        if (i === 0) {
            return true;
        }
        const atual = Math.trunc(complements[i - 1].order / 10);
        const next = Math.trunc(order / 10);
        if (atual !== next) {
            return true;
        }
    }

    calcTime(order
                 :
                 Order
    ) {
        const now = new Date();
        const start = new Date(order.date);
        // if(order.status = )


        const diffMs = now.getTime() - start.getTime();
        return Math.round(((diffMs % 86400000) % 3600000) / 60000);
    }

    sendToBag(order: Order) {
        super.showConfirmMessage('Atenção',
            'Você confirma que o produto está pronto para envio?', 'warning', () => {
                super.showConfirmMessage('Vamos nessa',
                    `Certo, vamos fazer o envio para você então. Para acionar um de nossos entregadores agora, clique em 'Enviar agora'`,
                    'warning', () => {
                        this.loading.show();
                        this.service.sendToBag(order.id).subscribe(
                            data => {
                                this.loading.hide();
                                this.onUpdate(order, 'delivery', true);
                            },
                            error => super.onError(error)
                        );
                    }, 'Enviar agora', 'Deixar pra outra hora');
            }, 'Sim, confirmo', 'Não, me enganei');
    }

    openOrder(url: string) {
        this.urlTackOrder = this.sanitizer.bypassSecurityTrustResourceUrl(environment.base_follow_url + '/view/follow/' + url);
        $('#trackOrder').modal('show');
    }

    trackOrder(order: Order) {
        return !super.isNullOrUndefined(order.saalve_bag_id) && (order.status === 'delivery');
    }

    switchTypeDelivery(order: Order) {
        if (order.is_desk === 1) {
            return 'Retirar no balcão';
        }
        return 'Entrega própria';
    }

    autoRefresh() {
        this.isAutoRefresh = !this.isAutoRefresh;
    }

    getColor() {
        return this.isAutoRefresh ? 'btn-danger' : 'btn-success';
    }
}
