import {ProductSize} from "./product-size";

export class ProductComplementItemPrice {

    id: string;
    product_complement_item_id: string;
    product_size_id: string;
    price: number;
    discount: number;
    size: ProductSize;

    constructor() {
        this.id = '';
        this.product_complement_item_id = '';
        this.price = 0.0;
        this.discount = 0;
    }

}