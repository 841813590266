export class Map {

    public lat: number;
    public lng: number;
    public deliveries: Delivery[];

    constructor() {
    }
}

export class Delivery {
    public id: string;
    public name: string;
    public value: number;
    public minimum_value: number;
    public drawresult: string;
    public color: string;
    public editing = false;
    public shape: Point[];
    public edit_name: string;
    public edit_value: number;
    public edit_minimum_value: number;

    constructor() {
        this.id = '';
        this.name = '';
        this.value = 0;
        this.minimum_value = 0;
        this.drawresult = '';
        this.color = '#' + (Math.random() * 0xFFFFFF << 0).toString(16);
        this.shape = [];
    }
}

export class Point {
    public lat: number;
    public lng: number;
}