import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {ProductComplement} from '../model/product_complement';
import {HttpClient} from '@angular/common/http';
import {ProductComplementItem} from "../model/product_complement_item";

/**
 * Created by Igor on 11/05/2017.
 */
@Injectable()
export class ComplementService extends ServiceBase {
    constructor(http: HttpClient) {
        super(http);
    }

    getComplements(hash: string) {
        const url = `${this.BASE_URL}/api/establishment/complement/${hash}`;
        return this.http.get(url);
    }

    create(model: ProductComplement) {
        const url = `${this.BASE_URL}/api/establishment/complement`;
        return this.http.post(url, model);
    }

    update(model: ProductComplement) {
        const url = `${this.BASE_URL}/api/establishment/complement`;
        return this.http.put(url, model);
    }

    delete(hash: string) {
        const url = `${this.BASE_URL}/api/establishment/complement/${hash}`;
        return this.http.delete(url);
    }

    duplicate(hash: string, prdId: string) {
        const url = `${this.BASE_URL}/api/establishment/complement/duplicate`;
        return this.http.post(url, `{"id":"${hash}", "product_id":"${prdId}"}`);
    }

    // COMPLEMENT ITEM
    createItem(model: ProductComplementItem) {
        const url = `${this.BASE_URL}/api/establishment/complement/item`;
        return this.http.post(url, model);
    }

    updateItem(model: ProductComplementItem) {
        const url = `${this.BASE_URL}/api/establishment/complement/item`;
        return this.http.post(url, model);
    }

    deleteItem(hash: string) {
        const url = `${this.BASE_URL}/api/establishment/complement/item/${hash}`;
        return this.http.delete(url);
    }

    // COMPLEMENT ITEM PRICE
    createItemPrice(model: ProductComplementItem) {
        const url = `${this.BASE_URL}/api/establishment/complement/item/price`;
        return this.http.post(url, model);
    }

    updateItemPrice(model: ProductComplementItem) {
        const url = `${this.BASE_URL}/api/establishment/complement/item/price`;
        return this.http.post(url, model);
    }

    deleteItemPrice(hash: string) {
        const url = `${this.BASE_URL}/api/establishment/complement/item/price/${hash}`;
        return this.http.delete(url);
    }

}
