import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {HttpClient} from '@angular/common/http';
import {Delivery} from "../model/map";
@Injectable()
export class MapService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getMapDetails() {
        const url = `${this.BASE_URL}/api/establishment/map/details/`;
        return this.http.get(url);
    }

    addShape(model: Delivery) {
        const url = `${this.BASE_URL}/api/establishment/delivery`;
        return this.http.post(url, model);
    }

    deleteShape(hash: string) {
        const url = `${this.BASE_URL}/api/establishment/delivery/${hash}`;
        return this.http.delete(url);
    }

    updateDelivery(model: Delivery) {
        const url = `${this.BASE_URL}/api/establishment/delivery`;
        return this.http.put(url, model);
    }

}
