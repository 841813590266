import {City} from './city';
import {Category} from './category';
import {State} from './state';

export class Establishment {

    id: string;
    social_reason: string;
    email: string;
    cnpj: string;
    cpf: string;
    photo: string;
    ddd: string;
    phone: string;
    ddd2: string;
    phone2: string;
    address: string;
    number: string;
    district_id: string;
    district: string;
    city_id: string;
    city: City;
    state_id: string;
    state: State;
    reference: string;
    postal_code: string;
    hash: string;
    image: string;
    estimated_time: string;
    category_id: string;
    category: Category;
    status: string;
    information: string;
    mp_status: string;

    constructor() {
        this.photo = 'assets/pages/img/nophoto.png';
        this.status = '';
        this.district = '';
        this.city = new City();
        this.category = new Category();
        this.state = new State();
    }
}
