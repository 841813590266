import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-term',
  templateUrl: './term.component.html',
  styleUrls: ['./term.component.css']
})
export class TermComponent extends BaseComponent implements OnInit {

  flag: boolean;

  constructor(public router: Router,
              public loading: NgxSpinnerService) {
      super(router, loading);
  }

  ngOnInit() {
  }

  verify() {
    if (this.flag === true) {
        this.router.navigate(['/cadastro-email']);
    }
  }

}
