import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {ResponseServer} from '../../model/response_server';
import {Printer} from '../../model/printer';
import {PrinterService} from '../../service/printer.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-printer',
  templateUrl: './printer.component.html',
  styleUrls: ['./printer.component.css'],
    providers: [PrinterService]
})
export class PrinterComponent extends BaseComponent implements OnInit {

    listPrinter: Printer[] = [];
    model: Printer = new Printer();
    passwordConfirm: string;
    pager: any = {};

    driver_url: string;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public service: PrinterService) {
        super(router, loading);
    }

    ngOnInit() {
        this.getList();
    }

    getList() {
        this.loading.show();
        this.service.getPrinter().subscribe(
            data => {
                this.loading.hide();
                this.listPrinter = data['printers'];
                this.driver_url = environment.driverUrl;
                this.setPage(1);
            },
            error => super.onError(error));
    }

    download() {
        window.open(this.driver_url);
    }

    newUser() {
        this.model = new Printer();
        super.onShowModal('#printer');
    }

    openEdit(printer: Printer) {
        this.model = printer;
        super.onShowModal('#printer');
    }

    onSubmitSave() {
        this.loading.show();
        this.service.create(this.model).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {

                }
                this.getList();
                super.onCloseModal('#printer');

            },
            error => super.onError(error));


    }


    remove(id: string) {
        this.loading.show();
        this.service.delete(id).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.getList();
                }
            },
            error => super.onError(error));
    }

    onRemove(id: string) {
        const self = this;
        super.showMessage('Atenção',
            'Tem certeza que deseja remover essa impressora?', 'warning', function (confirm = true) {
            if (confirm) {
                self.remove(id);
            }
        });
    }

    getStatusLabel(status) {
        switch (status) {
            case 'active':
                return 'Ativo';
            default:
                return 'Inativo';
        }
    }

    getStatusClass(status) {
        switch (status) {
            case 'active':
                return 'label-success';
            default:
                return 'label-danger';
        }
    }

    getTypeLabel(status) {
        switch (status) {
            case 'kitchen':
                return 'Cozinha';
            case 'balcony':
                return 'Balcão';
            case 'both':
                return 'Cozinha/Balcão';
            default:
                return '--';
        }
    }

    setPage(page: number) {
        this.pager = this.getPager(this.listPrinter.length, page, 5);
    }

}
