import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {Comment} from '../model/comment';
import {HttpClient} from '@angular/common/http';
/**
 * Created by Igor on 24/05/2017.
 */
@Injectable()
export class CommentService extends ServiceBase {

  constructor(http: HttpClient) {
    super(http);
  }

  get() {
    const url = `${this.BASE_URL}/api/establishment/get/comment`;
    return this.http.get(url);
  }

  commentEst(model: Comment){
    const url = `${this.BASE_URL}/api/establishment/comment/answer`;
    return this.http.post(url, model);
  }
}
