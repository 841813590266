import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {ProductSize} from "../model/product-size";
import {HttpClient} from "@angular/common/http";
/**
 * Created by igor on 08/08/17.
 */


@Injectable()
export class ProductSizeService extends ServiceBase {
    constructor(http: HttpClient) {
        super(http);
    }

    getSizes(hash: string) {
        const url = `${this.BASE_URL}/api/establishment/product/size_price/list/${hash}`;
        return this.http.get(url);
    }

    create(model: ProductSize) {
        const url = `${this.BASE_URL}/api/establishment/product/size_price/add`;
        return this.http.post(url, model);
    }

    update(model: ProductSize) {
        const url = `${this.BASE_URL}/api/establishment/product/size_price/update`;
        return this.http.post(url, model);
    }

    remove(id: string) {
        const url = `${this.BASE_URL}/api/establishment/product/size_price/remove/${id}`;
        return this.http.delete(url);
    }

}
