import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {Http} from '@angular/http';
import {ProductComplementItem} from '../model/product_complement_item';
import {HttpClient} from '@angular/common/http';

/**
 * Created by Igor on 11/05/2017.
 */
@Injectable()
export class OptionalItemService extends ServiceBase {
    constructor(http: HttpClient) {
        super(http);
    }

    getItens(hash: string) {
        const url = `${this.BASE_URL}/api/establishment/item/get/${hash}`;
        return this.http.get(url);
    }

    create(model: ProductComplementItem) {
        const url = `${this.BASE_URL}/api/establishment/item/add`;
        return this.http.post(url, model);
    }

    update(model: ProductComplementItem) {
        const url = `${this.BASE_URL}/api/establishment/item/update`;
        return this.http.post(url, model);
    }

    status(hash: string, status: boolean) {
        const url = `${this.BASE_URL}/api/establishment/item/status/`;
        const data = {"id": hash, "update": status};
        return this.http.post(url, data);
    }

    delete(hash: string) {
        const url = `${this.BASE_URL}/api/establishment/item/remove/${hash}`;
        return this.http.delete(url);
    }
}
