import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {BaseComponent} from '../../base/base.component';
import {EstablishmentCategory} from '../../model/establishment-category';
import {CategoryService} from '../../service/category.service';
import {ProductService} from '../../service/product.service';
import {Product} from '../../model/product';
import {ComplementService} from '../../service/complement.service';
import {ProductComplement} from '../../model/product_complement';
import {ProductComplementItem} from '../../model/product_complement_item';
import {OptionalItemService} from '../../service/optional.item.service';
import {Order} from '../../model/order';
import {OrderEst} from '../../model/order_est';
import {OrderItem} from '../../model/order_item';
import {OrderComplements} from '../../model/order_complements';
import {OrderOptional} from '../../model/order_optional';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-establishment-order',
    templateUrl: './establishment-order.component.html',
    styleUrls: ['./establishment-order.component.css'],
    providers: [CategoryService, ProductService, ComplementService, OptionalItemService]
})
export class EstablishmentOrderComponent extends BaseComponent implements OnInit {

    listCategory: EstablishmentCategory[] = [];
    listProducts: Product[] = [];
    listOptionals: ProductComplement[] = [];
    listOptionalItem: ProductComplementItem[] = [];
    order: OrderEst = new OrderEst();
    itemSelected: OrderItem = new OrderItem();
    listItem: OrderItem[] = [];

    changeItemP = false;
    prodHash  = '';
    optHash = '';
    model: Order = new Order();


    constructor(public router: Router,
                public categoryservice: CategoryService,
                public productservice: ProductService,
                public optionalservice: ComplementService,
                public loading: NgxSpinnerService,
                public optionalitemservice: OptionalItemService) {
        super(router, loading);
    }

    ngOnInit() {
        this.getCategorys();
    }

    getCategorys() {
        this.loading.show();
        this.categoryservice.getCategory().subscribe(
            data => {
                this.listCategory = data as EstablishmentCategory[];
                this.loading.hide();
            },
            error => super.onError(error));
    }

    getProducts(hash: string) {
        this.loading.show();
        this.productservice.getProductOrder(hash).subscribe(
            data => {
                this.listProducts = data as Product[];
                this.changeItemP = true;
                this.loading.hide();
            },
            error => super.onError(error));
    }

    getFlavors(hash: string) {
        this.loading.show();
        /*this.flavorservice.getFlavors(hash).subscribe(
            data => {
                this.listFlavors = data as EstablishmentFlavor[];
                this.loading.hide();
            },
            error => super.onError(error));*/
    }

    getOptionals(hash: string) {
        this.loading.show();
        /*this.optionalservice.getOptionals(hash).subscribe(
            data => {
                this.listOptionals = data as ProductComplement[];
                // console.log(data);
                this.loading.hide();
            },
            error => super.onError(error));*/
    }

    getOptionalItem(event, hash: string) {
        this.loading.show();
        this.optionalitemservice.getItens(hash).subscribe(
            data => {
                this.listOptionalItem = data as ProductComplementItem[];
                this.loading.hide();
            },
            error => super.onError(error));
    }

    backCategory() {
        this.changeItemP = false;
    }

    openListItem(hash: string) {
        super.onShowModal('#order');
        this.prodHash = hash;
        this.getOptionals(hash);
        this.getFlavors(hash);
    }

    addFlavor(event, flav: OrderComplements) {
        if (event.target.checked) {
            this.itemSelected.complements.push(flav);
        } else {
            this.itemSelected.complements.unshift(flav);
        }

    }

    addOptional(event, opti: OrderOptional) {

        // console.log(event);

        /*if (event) {
         this.itemSelected.optionals.push(opti);
         } else {
         this.itemSelected.optionals.unshift(opti);
         }*/
    }

    addItem() {
        this.order.items.push(this.itemSelected);
        // console.log(this.order.items);
        super.onCloseModal('#order');
    }


}
