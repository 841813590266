import {ServiceBase} from "./service-base";
import {Http} from "@angular/http";
import {Injectable} from "@angular/core";
import {HttpClient} from '@angular/common/http';
/**
 * Created by Igor on 12/05/2017.
 */
@Injectable()
export class AddressService extends ServiceBase {
    constructor(http: HttpClient) {
        super(http);
    }

    getState() {
        const url = `${this.BASE_URL}/api/register/state`;
        return this.http.get(url);
    }

    getCity(state: string) {
        const url = `${this.BASE_URL}/api/register/state/${state}/city`;
        return this.http.get(url);
    }

    // getDistrict(city: string) {
    //     const url = `${this.BASE_URL}/api/register/city/${city}/district/`;
    //     return this.http.get(url);
    // }

}