/**
 * Created by Igor on 05/06/2017.
 */

interface  Chart {
  init: Function;
  chartOrders: Function;
}

declare var Chart: Chart;
