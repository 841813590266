import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {PlanService} from '../../service/plan.service';
import {isNullOrUndefined} from 'util';
import {ResponseServer} from '../../model/response_server';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-credit-card',
    templateUrl: './credit-card.component.html',
    styleUrls: ['./credit-card.component.css'],
    providers: [PlanService]
})
export class CreditCardComponent extends BaseComponent implements OnInit {

    model = {
        plan_id: '',
        card_month: null,
        card_year: null,
        card_number: null,
        card_name: '',
        card_code: null
    };

    constructor(public router: Router,
                public route2: ActivatedRoute,
                public service: PlanService,
                public loading: NgxSpinnerService) {
        super(router, loading);
    }

    ngOnInit() {
        this.route2.params.subscribe(data => {
            const resulID = data['hash'];
            if (resulID) {
                this.model.plan_id = resulID;
            }
        });
    }

    onSave() {
        if (isNullOrUndefined(this.model.card_code) || this.model.card_code === '') {
            super.showMessage('Atenção', 'Informe o código.', 'info');
            return true;
        }
        if (isNullOrUndefined(this.model.card_month) || this.model.card_month === '') {
            super.showMessage('Atenção', 'Informe o mês de vencimento.', 'info');
            return true;
        }
        if (isNullOrUndefined(this.model.card_year) || this.model.card_year === '') {
            super.showMessage('Atenção', 'Informe o ano de vencimento.', 'info');
            return true;
        }
        if (isNullOrUndefined(this.model.card_name) || this.model.card_name === '') {
            super.showMessage('Atenção', 'Informe o nome do titular.', 'info');
            return true;
        }
        if (isNullOrUndefined(this.model.card_number) || this.model.card_number === '') {
            super.showMessage('Atenção', 'Informe o número do cartão.', 'info');
            return true;
        }
        this.loading.show();
        this.service.chooseBoleto(this.model).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.router.navigate(['/home/']);
                }
            },
            error => super.onError(error));
    }

}
