import {ServiceBase} from './service-base';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Category} from '../model/category';

/**
 * Created by Igor on 12/05/2017.
 */
@Injectable()
export class CategoryService extends ServiceBase {
    constructor(http: HttpClient) {
        super(http);
    }

    getCategoryEstablshimentRegister() {
        const url = `${this.BASE_URL}/api/register/category`;
        return this.http.get(url);
    }

    getCategoryEstablishment() {
        const url = `${this.BASE_URL}/api/establishment/category/list`;
        return this.http.get(url);
    }

    getCategory() {
        const url = `${this.BASE_URL}/api/establishment/product/category`;
        return this.http.get(url);
    }

    create(model) {
        const url = `${this.BASE_URL}/api/establishment/category/add`;
        return this.http.post(url, model);
    }

    update(model: Category) {
        const url = `${this.BASE_URL}/api/establishment/category/update`;
        return this.http.put(url, model);
    }

    status(hash: string , status: boolean) {
        const url = `${this.BASE_URL}/api/establishment/category/status/`;
        const data = {'id': hash, 'update': status};
        return this.http.post(url, data);
    }

    delete(hash: string) {
        const url = `${this.BASE_URL}/api/establishment/category/remove/${hash}`;
        return this.http.delete(url);
    }

}
