import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {MainComponent} from './view/main/main.component';
import {HomeComponent} from './view/home/home.component';
import {CommentComponent} from './view/comment/comment.component';
import {UserComponent} from './view/establishment-user/user.component';
import {ProductDetailComponent} from './view/product-detail/product-detail.component';
import {AttendenceComponent} from './view/establishment-attendence/attendence.component';
import {EstablishmentComponent} from './view/establishment/establishment.component';
import {AuthGuard} from './service/auth-guard.service';
import {LoginComponent} from './view/login/login.component';
import {ExtractComponent} from './view/extract/extract.component';
import {StatisticComponent} from './view/statistic/statistic.component';
import {OrderComponent} from './view/order/order.component';
import {TipsComponent} from './view/tips/tips.component';
import {PlanComponent} from './view/plan/plan.component';
import {MenuComponent} from './view/menu/menu.component';
import {ProductSizeComponent} from './view/product-size/product-size.component';
import {MainRegisterComponent} from './view/main-register/main-register.component';
import {WelcomeComponent} from './view/welcome/welcome.component';
import {RegisterComponent} from './view/register/register.component';
import {RegisterEmailComponent} from './view/register-email/register-email.component';
import {PaymentFormComponent} from './view/payment-form/payment-form.component';
import {TicketComponent} from './view/ticket/ticket.component';
import {CreditCardComponent} from './view/credit-card/credit-card.component';
import {CredentialComponent} from './view/credential/credential.component';
import {MapComponent} from './view/establishment-map/map.component';
import {GraphicOrderComponent} from './view/graphic-order/graphic-order.component';
import {TipsMarketingComponent} from './view/tips-marketing/tips-marketing.component';
import {TipsDiscontComponent} from './view/tips-discont/tips-discont.component';
import {TipsFinancialComponent} from './view/tips-financial/tips-financial.component';
import {TipsPhotoComponent} from './view/tips-photo/tips-photo.component';
import {GraphDeliveryComponent} from './view/graph-delivery/graph-delivery.component';
import {GraphRevenuesComponent} from './view/graph-revenues/graph-revenues.component';
import {ComplementComponent} from './view/product-complement/complement.component';
import {PrinterComponent} from './view/establishment-printer/printer.component';
import {TermComponent} from './view/term-establishment/term.component';
import {TermAppComponent} from './view/term-app/term-app.component';
import {CategoryComponent} from './view/category/category.component';
import {GraphicPerformanceComponent} from './view/graphic-performance/graphic-performance.component';
import {PaymentComponent} from './view/establishment-payment/payment.component';
import {OrderDetailComponent} from './view/order-detail/order-detail.component';
import {CuponComponent} from './view/cupon/cupon.component';
import {CreatePromocodeComponent} from './view/cupon/create-promocode/create-promocode.component';

const routes: Routes = [

    {path: 'login', component: LoginComponent},
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        data: {expectedRole: ['admin', 'employee', 'establishment']},
        children: [
            {
                path: '',
                component: HomeComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'home',
                component: HomeComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'establishment']}
            },
            {
                path: 'extract',
                component: ExtractComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'establishment']}
            },
            {
                path: 'statistic',
                component: StatisticComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'home/comments',
                component: CommentComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'establishment']}
            },
            {
                path: 'user',
                component: UserComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'establishment']}
            },
            {
                path: 'user/detail/:id',
                component: UserComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'establishment']}
            },
            {
                path: 'user/new',
                component: UserComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'establishment']}
            },
            {
                path: 'product/new',
                component: ProductDetailComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'menu',
                component: MenuComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'size',
                component: ProductSizeComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'product/detail/:id',
                component: ProductDetailComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'establishment',
                component: EstablishmentComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'establishment']}
            },
            {
                path: 'establishment/establishment-attendence',
                component: AttendenceComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'order',
                component: OrderComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'tips',
                component: TipsComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'map',
                component: MapComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'printer',
                component: PrinterComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'graphic-order',
                component: GraphicOrderComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'map',
                component: MapComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'graphic-delivery',
                component: GraphDeliveryComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'graphic-revenues',
                component: GraphRevenuesComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'graphic-performance',
                component: GraphicPerformanceComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'marketing',
                component: TipsMarketingComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'discont',
                component: TipsDiscontComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'financial',
                component: TipsFinancialComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'photo',
                component: TipsPhotoComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'product-complement',
                component: ComplementComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'comments',
                component: CommentComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'promocode',
                component: CuponComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'create-promocode',
                component: CreatePromocodeComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'create-promocode/:id',
                component: CreatePromocodeComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'category',
                component: CategoryComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'payment',
                component: PaymentComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            },
            {
                path: 'order-detail/:hash',
                component: OrderDetailComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'employee', 'establishment']}
            }
        ]
    },
    {
        path: '',
        component: MainRegisterComponent,
        canActivate: [],
        children: [
            {path: 'cadastro', component: RegisterComponent, canActivate: []},
            {path: 'cadastro/:hash', component: RegisterComponent, canActivate: []},
            {
                path: 'cartao/:hash',
                component: CreditCardComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'establishment']}
            },
            {
                path: 'boleto/:hash',
                component: TicketComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'establishment']}
            },
            {
                path: 'pagamento/:hash',
                component: PaymentFormComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'establishment']}
            },
            {
                path: 'bemvindo',
                component: WelcomeComponent,
                canActivate: []
            },
            {
                path: 'credencial',
                component: CredentialComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'establishment']}
            },
            {
                path: 'plano',
                component: PlanComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'establishment']}
            },
            {
                path: 'cadastro-email',
                component: RegisterEmailComponent,
                canActivate: [AuthGuard],
                data: {expectedRole: ['admin', 'establishment']}
            },
        ]
    },

    {
        path: 'termo-de-uso-estabelecimento',
        component: TermComponent,
        canActivate: [],
        children: []
    },
    {
        path: 'termo-de-uso',
        component: TermAppComponent,
        canActivate: [],
        children: []
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    declarations: []
})
export class AppRouteModule {
}
