import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {EstablishmentCategory} from '../../model/establishment-category';
import {EstablishmentCategoryService} from '../../service/establishment.category.service';
import {ResponseServer} from '../../model/response_server';
import {BaseComponent} from '../../base/base.component';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-establishment-category',
    templateUrl: './establishment-category.component.html',
    styleUrls: ['./establishment-category.component.css'],
    providers: [EstablishmentCategoryService]
})
export class EstablishmentCategoryComponent extends BaseComponent implements OnInit {

    listEstCategory: EstablishmentCategory[] = [];
    model: EstablishmentCategory = new EstablishmentCategory();

    _editHash = '';
    pager: any = {};

    constructor(public router: Router,
                public service: EstablishmentCategoryService,
                public loading: NgxSpinnerService) {
        super(router, loading);
    }

    ngOnInit() {
        this.getList();
    }

    getList() {
        this.loading.show();
        this.service.getCategory().subscribe(
            data => {
                this.loading.hide();
                this.listEstCategory = data as EstablishmentCategory[];
                this.setPage(1);
            },
            error => super.onError(error));
    }

    onSubmitSave() {
        if (typeof this.model.name === 'undefined') {
            super.showMessage('Atenção', 'Insira o Nome da Categoria', 'info');
            return;
        }

        this.loading.show();

        this.service.create(this.model).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.model = new EstablishmentCategory();
                    this.getList();
                }
            },
            error => super.onError(error));
    }

    onEditRow(hash: string) {
        this._editHash = hash;
    }

    onSaveRow(cat: EstablishmentCategory) {
        this._editHash = '';
        this.loading.show();
        this.service.update(cat).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.getList();
                }
            },
            error => super.onError(error));
    }

    remove(hash: string) {
        this.loading.show();
        this.service.delete(hash).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.getList();
                }

            },
            error => super.onError(error));
    }

    onRemove(hash: string) {
        const self = this;
        super.showConfirmMessage('Atenção',
            'Tem certeza que deseja remover este sabor?',
            'warning', () => {
                self.remove(hash);
            });
    }

    onUpdateStatus(hash: string) {
        const self = this;
        super.showConfirmMessage('Atenção',
            'Tem certeza que deseja alterar o status dessa categoria?',
            'warning', () => {
                self.updateStatus(hash);
            });
    }

    updateStatus(hash: string) {
        this.loading.show();
        // console.log(hash);
        this.service.status(hash).subscribe(
            data => {
                const result = data as ResponseServer;
                this.loading.hide();
                this.model.status = data['status'];
            },
            error => super.onError(error));
    }

    Cancelar() {
        this._editHash = '';
    }

    setPage(page: number) {
        this.pager = this.getPager(this.listEstCategory.length, page, 5);
    }

}
