import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {User} from '../model/user';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user != null && user.id) {
            return this.validateUser(user, route);
        } else {
            this.router.navigate(['/login']);
        }
        return true;
    }

    validateUser(user: User, route: ActivatedRouteSnapshot) {
        if (route.data.expectedRole.indexOf(user.type) !== -1) {
            return true;
        } else {
            // App.showAlert('Acesso não autorizado', 'Você não tem permissão de acesso, fale com seu administrador.', 'OK', '', 'warning', null);
            // this.router.navigate(['/login']);
            return false;
        }

        // let profile = [];
        //
        // switch (user.function) {
        //     case 1:
        //         profile = ['/', '/home', '/user', '/order', '/establishment', '/establishment/establishment-user', '/establishment/new',
        //             '/establishment/detail', '/category', '/tips', '/plan', '/product/optional', '/product/new', '/menu',
        //             '/establishment/payment', '/bemvindo', '/cadastro', '/cadastro-email', '/forma-pagamento', '/boleto',
        //             '/cartao', '/credenciais'];
        //         break;
        //     case 2:
        //         profile = ['/', '/home', '/user', '/order', '/establishment', '/establishment/establishment-user', '/establishment/new',
        //             '/establishment/detail', '/category', '/tips', '/plan', '/product/optional', '/product/new', '/menu',
        //             '/establishment/payment', '/bemvindo', '/cadastro', '/cadastro-email', '/forma-pagamento', '/boleto',
        //             '/cartao', '/credenciais'];
        //         break;
        //     default:
        //         return true;
        // }
        //
        // if (profile.indexOf(url) >= 0) {
        //     return true;
        // } else {
        //     App.showAlert('Acesso não autorizado', 'Você não tem permissão de acesso, fale com seu administrador.', 'OK', '', 'warning', null);
        //     this.router.navigate(['/login']);
        //     return false;
        // }
    }

}
