import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {ResponseServer} from '../../model/response_server';
import {ProductService} from '../../service/product.service';
import {Product} from '../../model/product';
import {NgxSpinnerService} from 'ngx-spinner';
import {RequestParameters} from '../../model/request_parameters';
import {isNullOrUndefined} from 'util';
import {EstablishmentCategory} from '../../model/establishment-category';
import {CategoryService} from '../../service/category.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css'],
    providers: [ProductService, CategoryService]
})
export class MenuComponent extends BaseComponent implements OnInit {

    listCategory: EstablishmentCategory[] = [];
    listProducts: Product[] = [];
    model: Product = new Product();
    pager: any = {};

    constructor(public router: Router,
                public service: ProductService,
                public categoryService: CategoryService,
                public loading: NgxSpinnerService) {
        super(router, loading);
        this.request = super.getRequest('product');
        if (isNullOrUndefined(this.request)) {
            this.request = new RequestParameters();
        }
    }

    request: RequestParameters;

    ngOnInit() {
        this.getList();
    }

    getList() {
        this.loading.show();
        super.setRequest('product', this.request);
        this.getCategorys();
        this.service.getProducts(this.request).subscribe(
            data => {
                this.loading.hide();
                this.listProducts = data as Product[];
                this.setPage(1);
            },
            error => super.onError(error));
    }

    clearRequest() {
        this.request = new RequestParameters();
        super.setRequest('product', this.request);
        this.getList();
    }

    updateStatus(hash: string) {
        this.loading.show();
        // console.log(hash);
        this.service.status(hash).subscribe(
            data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.getList();
                }

            },
            error => super.onError(error));
    }

    onDuplicate(hash: string) {
        const self = this;
        super.showConfirmMessage('Atenção', 'Tem certeza que deseja duplicar este produto?',
            'warning', () => {
                self.duplicateProduct(hash);
            });
    }

    duplicateProduct(hash: string) {
        this.loading.show();
        this.service.duplicateProduct(hash).subscribe(
            data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.getList();
                }

            },
            error => super.onError(error));
    }

    onEdit(prod: Product) {
        this.router.navigate(['/product/detail', prod.id]);
    }

    newProduct() {
        this.router.navigate(['/product/new']);
    }

    remove(hash: string) {
        this.loading.show();
        this.service.delete(hash).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.getList();
                }

            },
            error => super.onError(error));
    }

    onRemove(hash: string) {
        const self = this;
        super.showConfirmMessage('Atenção', 'Tem certeza que deseja remover esse produto?',
            'warning', () => {
                self.remove(hash);
            });
    }

    setPage(page: number) {
        this.pager = this.getPager(this.listProducts.length, page, 30);
    }

    getStatusLabel(status) {
        switch (status) {
            case 'active':
                return 'Pausar Vendas';
            default:
                return 'Ativar Vendas';
        }
    }

    getStatusClass(status) {
        switch (status) {
            case 'active':
                return 'label-success';
            default:
                return 'label-danger';
        }
    }

    Cancelar() {
        this.getList();
    }

    changeProductStatus(p: Product) {
        this.service.status(p.id).subscribe(
            data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    p.status = result.status;
                }
            },
            error => super.onError(error));
    }

    getCategorys() {
        this.loading.show();
        this.categoryService.getCategory().subscribe(
            data => {
                this.listCategory = data as EstablishmentCategory[];
                this.loading.hide();
            },
            error => super.onError(error));
    }
}
