import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class MercadopagoService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    mercadopago() {
        const url = `${this.BASE_URL}/api/mp/authorize`;
        return this.http.post(url, null);
    }

}
