import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pointReplacerMoney'
})

export class PointReplacerMoneyPipe implements PipeTransform {
    transform(value: string, args: any[]): string {
        if (value) {
            const val = value.split('.');
            let cents = '00';
            let integer = '0';
            if (val.length === 1) {
                integer = val[0].replace(/,/g, '.');
            } else {
                if (val[1].length == 1)
                    cents = val[1] + "0";
                else cents = val[1];
                integer = val[0].replace(/,/g, '.');
            }
            return integer + ',' + cents;
        }
    }
}