import {EventEmitter, Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {Http} from '@angular/http';
import {Observable} from 'rxjs/';
import {HttpClient} from '@angular/common/http';
import {FileAmazon} from '../model/file-amazon';

@Injectable()
export class UploadService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    uploadEstablishment(file) {

        const url = `${this.BASE_URL}/api/establishment/update/photo`;
        return Observable.create(observer => {

            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();

            if (file != null) {
                formData.append('file', file, file.name);
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.upload.onprogress = (event) => {
                const progress = Math.round(event.loaded / event.total * 100);
            };

            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.getToken());
            xhr.send(formData);
        });
    }

    uploadProduct(file, id) {

        const url = `${this.BASE_URL}/api/establishment/product/update/photo`;
        return Observable.create(observer => {

            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();

            formData.append('product_id', id);
            if (file != null) {
                formData.append('file', file, file.name);
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.upload.onprogress = (event) => {
                const progress = Math.round(event.loaded / event.total * 100);
            };

            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.getToken());
            xhr.send(formData);
        });
    }

}
