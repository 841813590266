import {Component, OnInit} from '@angular/core';
// import {Http} from '@angular/http';
import {Router} from '@angular/router';
import {CommentService} from '../../service/comment.service';
import {Comment} from '../../model/comment';
import {PushService} from '../../service/push.service';
import {ResponseServer} from '../../model/response_server';
import {BaseComponent} from '../../base/base.component';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-comment',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.css'],
    providers: [CommentService]
})
export class CommentComponent extends BaseComponent implements OnInit {

    listComment: Comment[] = [];
    model: Comment = new Comment();
    _editID = 0;
    pager: any = {};

    count: number;

    constructor(public router: Router,
                public service: CommentService,
                public loading: NgxSpinnerService,
                public pushservice: PushService) {
        super(router, loading);
    }

    ngOnInit() {
        this.getList();
        this.pushservice.eventNewComment.subscribe(count => {
            this.getList();
        });
    }

    getList() {
        this.loading.show();
        this.service.get().subscribe(
            data => {
                this.loading.hide();
                const list = data['comments'] as Comment[];
                if (list.length !== 0) {
                    this.pushservice.lastitemcomments = list[0].id;
                    this.listComment = list;
                    for (const x of this.listComment) {
                        x.listStar = [];
                        this.count = 0;
                        while (this.count < x.rating) {
                            x.listStar.push(this.count);
                            this.count++;
                        }
                    }
                    this.setPage(1);
                    return;
                }
            },
            error => super.onError(error));
    }

    onEditComment(id) {
        this._editID = id;
    }

    saveEditComment(comment: Comment) {
        this.loading.show();
        this._editID = 0;
        this.service.commentEst(comment).subscribe({
            next: (data) => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.getList();
                }

            },
            error: (error) => super.onError(error)
        });

    }

    Cancelar() {
        this._editID = 0;
    }

    setPage(page: number) {
        this.pager = this.getPager(this.listComment.length, page, 10);
    }

}
