import {OrderItem} from "./order_item";
/**
 * Created by Igor on 09/06/2017.
 */
export class OrderEst {

  id: number;
  payment: string;
  total: number;
  discount: number;
  items: OrderItem[];

  constructor() {
    this.items = [];
  }
}
