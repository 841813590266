import {environment} from '../environments/environment';

export class Settings {
    public static BASE_URL = environment.base_url;
    public static PUSHER_CLUSTER = environment.pusher_cluster;
    public static PUSHER_KEY = environment.pusher_key;
    public static PUSHER_END_POINT = environment.base_url + '/api/pusher/auth';

    public static FIREBASE_SHARE_LINK_URL = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBZeB9sjxwwac2l_9Bs6WYHx3ht2ikktpQ';
    public static DOMAIN_URI_PREFIX = 'https://saalve.page.link';
    public static LINK = 'https://saalve.app/establishment/';
    public static ANDROID_PACKAGE_NAME = '';
    public static IOS_BUNDLE_ID = '';
    public static IOS_APP_STORE_ID = '1438780544';

    public static VERSION = '1.0.7';

    constructor() {
    }
}
