import {Component, OnInit} from '@angular/core';
import {User} from '../../model/user';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent extends BaseComponent implements OnInit {

    modelUser: User = new User();

    constructor(public router: Router,
                public loading: NgxSpinnerService) {
        super(router, loading);
    }

    ngOnInit() {
        this.modelUser = super.getUser();
    }

    openDesktopPage() {
        window.open('https://juca.app/desktop', '_blank');
    }
}
