import {EstablishmentCategory} from "./establishment-category";
import {ProductComplement} from "./product_complement";

/**
 * Created by Igor on 10/05/2017.
 */
export class Product {

    id: string;
    name: string;
    hash: string;
    code: number;
    category: EstablishmentCategory;
    optional: [ProductComplement];
    flavor_count: number;
    has_sizes: boolean;
    price: number;
    discount_price: number;
    establishment_category_id: string;
    photo: string;
    category_name: string;
    status: string;
    description: string;
    price_type: string;
    discount: number;
    promotion: boolean;
    sunday: boolean;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    more_than_one_price: number;
    priority: number;

    constructor() {
        this.hash = '';
        this.photo = 'assets/pages/img/nophoto.png';
        this.status = '';
        this.description = '';
        this.price_type = 'fixed';
        this.priority = 0;
    }

}
