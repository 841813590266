import {Router} from '@angular/router';
import {isNullOrUndefined, isUndefined} from 'util';
import {User} from '../model/user';
import {SwalComponent} from '@toverux/ngx-sweetalert2';
import {NgxSpinnerService} from 'ngx-spinner';
import {RequestParameters} from '../model/request_parameters';

declare const jquery: any;
declare const $: any;

declare function openModal(id: string, options: string): any;

declare function closeModal(id: string): any;

declare function pusher(id, token, cluster, endpoint, key, callback): any;

declare function getListEstablishment(): any;

declare function cropImage(id: string): any;

declare function changeTab(id: string, tab: string): any;

export class BaseComponent {

    url = window.location.pathname;

    loggedUser: User = new User();

    constructor(public router: Router,
                public loading: NgxSpinnerService) {
        this.loggedUser = this.getUser();
    }

    getUser() {
        return JSON.parse(localStorage.getItem('user')) as User;
    }

    setUser(user: User) {
        localStorage.setItem('user', JSON.stringify(user));
        this.loggedUser = user;
    }

    getRequest(key: string) {
        return JSON.parse(localStorage.getItem(key)) as RequestParameters;
    }

    setRequest(key: string, request: RequestParameters) {
        localStorage.setItem(key, JSON.stringify(request));
    }

    onShowModal(id: string) {
        openModal(id, '');
    }

    onCloseModal(id: string) {
        closeModal(id);
    }

    onPusher(id: string, token: string, cluster: string, endpoint: string, key: string, callback: any) {
        if (isUndefined(pusher(id, token, cluster, endpoint, key, callback))) {
            return;
        }

        pusher(id, token, cluster, endpoint, key, callback);
    }

    onError(error) {
        this.loading.hide();

        if (error.status === 401) {
            this.router.navigate(['/login']);
            return;
        }

        // const e = JSON.parse(error['error']);

        if (error.status === 400) {
            this.showMessage('Atenção', error['error']['message'], 'info');
            return;
        }

        if (error.status === 500) {
            this.showMessage('Atenção', 'Ocorreu um erro com o servidor!', 'info');
            return;
        }

        // App.showMessage('Erro', e.message, 'OK', '', 'error', null);

        return (error);
    }

    showMessage(title: string, message: string, mode: any = 'info', callback: any = null) {
        const dialog = new SwalComponent({
            title: title,
            text: message,
            type: mode,
            confirmButtonColor: '#26a1ab'
        });

        dialog.show().then(result => {
            if (callback != null) {
                callback();
            }
        });
    }

    isNullOrUndefined(value) {
        return typeof value === 'undefined' || value == null || value.length === 0;
    }

    showConfirmMessage(title: string, message: string, mode: any, callback: any = null,
                       confirmButtonText = 'Tenho Certeza',
                       cancelButtonText = 'Cancelar') {
        const dialog = new SwalComponent({
            title: title,
            text: message,
            type: mode,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            confirmButtonColor: '#26a1ab',
            cancelButtonColor: '#ff5847'
        });

        dialog.show().then(result => {
            if (!isNullOrUndefined(result.value) && result.value === true) {
                if (callback != null) {
                    callback();
                }
            }
        });
    }

    getDateNowNoFormat(days: number = 0) {
        const date = new Date();
        if (days !== 0) {
            date.setDate(date.getDate() + days);
        }

        const day = date.getDate().toString().padLeft(2, '0');
        const month = (date.getMonth() + 1).toString().padLeft(2, '0');
        const year = date.getFullYear();
        return year + '-' + month + '-' + day;
    }

    getYear() {
        const date = new Date;
        date.setDate(date.getDate());
        const year = date.getFullYear();
        return year;

    }

    onChangeTab(id: string, tab: string) {
        if (isUndefined(changeTab)) {
            return;
        }
        changeTab(id, tab);
    }

    getPager(items: number, currentPage: number = 1, pageSize: number = 10) {

        // calcula o total de páginas
        const totalPages = Math.ceil(items / pageSize);

        let startPage: number, endPage: number;
        if (totalPages <= 10) {
            // menos de 10 páginas
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }
        // clacula o começo e o fim dos items da página
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize - 1, items - 1);

        //
        const pages = 0; // _range(startPage, endPage + 1);

        // retornos
        return {
            items: items,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };


    }

    onCropImage(id: string) {
        if (isUndefined(cropImage)) {
            return;
        }
        cropImage(id);
    }
}
