export class Category {

    id: string;
    name: string;
    status: number;
    establishment_id: string;
    created_at: string;
    updated_at: string;

    constructor(){

    }
}