/**
 * Created by Igor on 10/05/2017.
 */
import {ProductComplementItem} from "./product_complement_item";

export class ProductComplement {

    id: string;
    establishment_product_id: string;
    required: number;
    item_count: number;
    name: string;
    status: string;
    type: string;
    order: number;
    price_mode: string;
    editing_comp: boolean;
    editing_item: boolean;

    items: ProductComplementItem[];

    constructor() {
        this.editing_comp = false;
        this.editing_item = false;
        this.id = '';
        this.required = 0;
        this.type = 'optional';
        this.status = 'active';
        this.item_count = 0;
        this.order = 0;
        this.items = [];
    }
}
