export class EstablishmentCategory {

    id: string;
    name: string;
    hash: string;
    priotity: number;
    status: string;
    priority: number;

    constructor() {
        this.status = 'active';
        this.priotity = 0;
    }

}
