import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tips-photo',
  templateUrl: './tips-photo.component.html',
  styleUrls: ['./tips-photo.component.css']
})
export class TipsPhotoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
