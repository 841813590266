import {Category} from "./category";

export class City {

    id: string;
    name: string;
    status: number;
    category_id: Category[];

    constructor(){
        this.category_id = [];
    }

}