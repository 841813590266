import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseComponent} from '../../base/base.component';
import {Plan} from '../../model/plan';
import {PaymentService} from '../../service/payment.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.css'],
    providers: [PaymentService]
})
export class PaymentFormComponent extends BaseComponent implements OnInit {

    model: Plan = new Plan();
    paymentForm: number;

  constructor(public router: Router,
              public service: PaymentService,
              public route2: ActivatedRoute,
              public loading: NgxSpinnerService) {
      super(router, loading);
  }

  ngOnInit() {
      this.route2.params.subscribe(data => {
          const resulID = data['hash'];
          if (resulID) {
              this.model.id = resulID;
          }
      });
  }

  onChoose() {

    if (this.paymentForm === 1) {
        this.router.navigate(['/boleto/', this.model.id]);
    } else if (this.paymentForm === 0) {
        this.router.navigate(['/cartao/', this.model.id]);
    } else {
        this.service.mpConnect().subscribe(
            data => {
                this.loading.hide();
            },
            error => super.onError(error));
    }
  }

}
