export class State {

    id: string;
    name: string;
    state: string;
    status: number;
    created_at: string;
    updated_at: string;

    constructor(){

    }
}