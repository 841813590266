// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: true,
    driverUrl: 'https://saalve-painel.duka.digital/assets/printer/Saalve.zip',
    base_url: 'https://saalve-service.duka.digital',
    // base_url: 'http://localhost:8000',
    // base_url: 'https://service.saalve.app',
    pusher_cluster: 'sa1',
    pusher_key: '15f2cb2f260bd4351fe1',
    base_follow_url: 'https://saalve-bag-service.duka.digital',
    checkout_url_activate: 'https://saalve-checkout-web.duka.digital/'
    // checkout_url_activate: 'http://localhost:4300/'
};
