import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'underscore';

@Pipe({
  name: 'paginator'
})
export class PaginatorPipe implements PipeTransform {


  transform(list: [any], param?: any): any {
    //console.log(param);
   /// param.p = this.getPager(list.length, param.p.currentPage, param.size);
    return list.slice(param.p.startIndex, param.p.endIndex + 1);
  }

}
