import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {GraphService} from '../../service/graph.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-graphic-order',
    templateUrl: './graphic-order.component.html',
    styleUrls: ['./graphic-order.component.css'],
    providers: [GraphService]
})
export class GraphicOrderComponent extends BaseComponent implements OnInit {

    date = '';

    years = [];

    requestDaily = {
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
    };

    requestMonth = {
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
    };

    requestEvolution = {
        quarter: this.getQuarter(),
        year: new Date().getFullYear()
    };

    constructor(public router: Router,
                public service: GraphService,
                public loading: NgxSpinnerService) {
        super(router, loading);
        for (let i = new Date().getFullYear(); i >= 2021; i--) {
            this.years.push(i);
        }
    }

    ngOnInit() {
        this.getChartEvolutionDailyOrder();
        this.getChartEvolutionOrder();
        this.getChartOrderMonth();
    }

    public getChartEvolutionDailyOrder() {
        this.date = this.requestDaily.month + '/' + this.requestDaily.year;
        this.loading.show();
        this.service.getChartEvolutionDailyOrder(this.date).subscribe({
            next: (data) => {
                this.loading.hide();
                Chart.chartEvolutionDailyOrder(data);
            },
            error: (error) => super.onError(error)
        });
    }

    public getChartOrderMonth() {
        this.loading.show();
        this.service.getChartOrderMonth().subscribe({
            next: (data) => {
                this.loading.hide();
                Chart.chartOrderMonth(data);
            },
            error: (error) => super.onError(error)
        });
    }

    public getChartEvolutionOrder() {
        this.date = this.requestEvolution.quarter + '/' + this.requestEvolution.year;
        this.loading.show();
        this.service.getChartEvolutionOrder(this.date).subscribe(
            {
                next: (data) => {
                    this.loading.hide();
                    Chart.chartEvolutionOrder(data);
                },
                error: (error) => super.onError(error)
            });
    }

    getQuarter() {
        switch (new Date().getMonth()) {
            case 1:
                return 1;
            case 2:
                return 1;
            case 3:
                return 1;
            case 4:
                return 2;
            case 5:
                return 2;
            case 6:
                return 2;
            case 7:
                return 3;
            case 8:
                return 3;
            case 9:
                return 3;
            case 10:
                return 4;
            case 11:
                return 4;
            case 12:
                return 4;
            default:
                return 1;
        }
    }

}
