import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductComplement} from '../../model/product_complement';
import {ProductSizeService} from '../../service/product-size.service';
import {isUndefined} from 'util';
import {ProductSize} from '../../model/product-size';
import {ProductComplementItem} from '../../model/product_complement_item';
import {ComplementService} from '../../service/complement.service';
import {ResponseServer} from '../../model/response_server';
import {ProductComplementItemPrice} from '../../model/product_complement_item_price';
import {NgxSpinnerService} from 'ngx-spinner';
import {Subject, Subscription} from 'rxjs/index';
import {EventBusService, Events} from "../../service/event-bus.service";

@Component({
    selector: 'app-complement',
    templateUrl: './complement.component.html',
    styleUrls: ['./complement.component.css'],
    providers: [ComplementService, ProductSizeService]
})
export class ComplementComponent extends BaseComponent implements OnInit, OnDestroy {

    listComplements: ProductComplement[] = [];
    modelComplement: ProductComplement = new ProductComplement();
    modelComplementItem: ProductComplementItem = new ProductComplementItem();
    eventbusSub: Subscription;
    listSize: ProductSize[] = [];
    prodStatus: string;
    prodId = '';

    @Input() changing: Subject<boolean>;

    @Input()
    set productID(id: string) {
        if (id !== undefined) {
            this.prodId = id;
        }
    }

    @Input()
    set productStatus(status: string) {
        this.prodStatus = status;
    }

    constructor(public router: Router,
                public service: ComplementService,
                public route: ActivatedRoute,
                private eventbus: EventBusService,
                public sizeService: ProductSizeService,
                public loading: NgxSpinnerService) {
        super(router, loading);
    }


    ngOnInit() {
        this.changing.subscribe(v => {
            this.getSizeList();
        });
        this.getSizeList();
        this.eventbusSub = this.eventbus.on(Events.isComplement, () => this.getList());
    }

    ngOnDestroy(): void {
        this.eventbusSub.unsubscribe();
    }

    getList() {
        this.loading.show();
        this.service.getComplements(this.prodId).subscribe(
            data => {
                this.loading.hide();
                this.listComplements = data as ProductComplement[];
            },
            error => super.onError(error));
    }

    getSizeList() {
        this.loading.show();
        this.sizeService.getSizes(this.prodId).subscribe(
            data => {
                this.loading.hide();
                this.listSize = data as ProductSize[];
                for (let i = 0; i < this.listSize.length; i++) {
                    const price = new ProductComplementItemPrice();
                    price.size = this.listSize[i];
                    this.modelComplementItem.prices.push(price);
                }
            },
            error => super.onError(error));
    }

    // COMPLEMENTS
    openComplement(model: ProductComplement) {
        this.modelComplement = Object.assign({}, model);
        const editArea = document.getElementById('prd_complement');
        if (!editArea.classList.contains('show')) {
            editArea.classList.add('show');
        }
        this.scroll(document.getElementById('prd_complement'));
    }

    scroll(el: HTMLElement) {
        el.scrollIntoView({behavior: 'smooth', block: 'end'});
    }

    importComplement(id: string) {
        this.loading.show();
        this.service.duplicate(id, this.prodId).subscribe(data => {
                this.loading.hide();
                const result = data as ResponseServer;
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.getList();
                }
            },
            error => super.onError(error));
    }

    onComplementValidate() {

        if (isUndefined(this.modelComplement.name) || this.modelComplement.name.length < 0) {
            super.showMessage('Atenção', 'Insira o Nome do Complemento.', 'info');
            return false;
        }

        if (isUndefined(this.modelComplement.item_count) || this.modelComplement.item_count < 0) {
            super.showMessage('Atenção', 'Quantidade de Selecionáveis inválida.', 'info');
            return false;
        }

        if (this.modelComplement.item_count <= 1) {
            this.modelComplement.price_mode = 'normal';
        }

        return true;
    }

    complementCancel() {
        this.modelComplement = new ProductComplement();
    }

    complementSave() {

        if (!this.onComplementValidate()) {
            return;
        }

        this.loading.show();
        this.modelComplement.establishment_product_id = this.prodId;

        const s = this.modelComplement.id.length === 0 ?
            this.service.create(this.modelComplement)
            :
            this.service.update(this.modelComplement);

        s.subscribe(data => {
                const el = document.getElementById('prd_complement');
                el.classList.remove('show');
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                }
                this.complementCancel();
                this.getList();
            },
            error => super.onError(error));
    }

    onRemoveComplement(hash: string) {
        const self = this;
        super.showConfirmMessage('Atenção', 'Tem certeza que deseja remover esse complemento?',
            'warning', () => {
                self.complementRemove(hash);
            });
    }

    complementRemove(hash: string) {
        this.loading.show();
        this.service.delete(hash).subscribe(data => {
                this.loading.hide();
                const result = data as ResponseServer;
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.getList();
                }
            },
            error => super.onError(error));
    }

    // COMPLEMENT ITEMS
    openComplementItem(complementId: string, model: ProductComplementItem = new ProductComplementItem(), index: number = -1) {
        if (model.id.length !== 0) {
            this.modelComplementItem = Object.assign({}, model);
        } else {
            this.modelComplementItem = Object.assign({}, model);
            this.modelComplementItem.product_complement_id = complementId;
        }

        for (const size of this.listSize) {
            const p = model.prices.filter(v => v.size.id === size.id);
            if (p.length === 0) {
                const modelPrice = new ProductComplementItemPrice();
                modelPrice.product_size_id = size.id;
                modelPrice.size = size;
                modelPrice.product_complement_item_id = this.modelComplementItem.id;
                this.modelComplementItem.prices.push(modelPrice);
            }
        }

        if (index > -1) {
            const editArea = document.getElementById('item' + index);
            if (!editArea.classList.contains('show')) {
                editArea.classList.add('show');
            }
            this.scroll(document.getElementById('item' + index));
        }
    }

    onComplementItemValidate() {
        if (isUndefined(this.modelComplementItem.name) || this.modelComplementItem.name.length < 0) {
            super.showMessage('Atenção', 'O nome do item é obrigatório.', 'info');
            return false;
        }
        return true;
    }

    complementItemSave() {
        if (!this.onComplementItemValidate()) {
            return;
        }

        this.loading.show();

        const s = this.modelComplementItem.id.length === 0 ?
            this.service.createItem(this.modelComplementItem)
            :
            this.service.updateItem(this.modelComplementItem);

        s.subscribe(data => {
                this.loading.hide();
                const result = data as ResponseServer;
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                }
                super.onCloseModal('#complement_item');
                this.getList();
            },
            error => super.onError(error));
    }

    onRemoveComplementItem(hash: string) {
        const self = this;
        super.showConfirmMessage('Atenção', 'Tem certeza que deseja remover esse item?',
            'warning', () => {
                self.complementItemRemove(hash);
            });

    }

    complementItemRemove(hash: string) {
        this.loading.show();
        this.service.deleteItem(hash).subscribe(data => {
                this.loading.hide();
                const result = data as ResponseServer;
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.getList();
                }
            },
            error => super.onError(error));
    }

    getStatusLabel(status) {
        switch (status) {
            case 'active':
                return 'Ativo';
            default:
                return 'Inativo';
        }
    }

    getStatusClass(status) {
        switch (status) {
            case 'active':
                return 'label-success';
            default:
                return 'label-danger';
        }
    }

    getTypeName(type) {
        switch (type) {
            case 'optional':
                return 'Opcional';
            case 'flavor':
                return 'Sabor';
            case 'product':
                return 'SubProduto';
        }
    }


}
