import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {PromocodeService} from "../../service/promocode.service";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {Promocode} from "../../model/promocode";
import {ResponseServer} from "../../model/response_server";
import {Product} from "../../model/product";

@Component({
    selector: 'app-cupon',
    templateUrl: './cupon.component.html',
    styleUrls: ['./cupon.component.css'],
    providers: [PromocodeService]
})
export class CuponComponent extends BaseComponent implements OnInit {

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public service: PromocodeService) {
        super(router, loading);
    }

    list: Promocode[] = [];

    request = {
        date_start: super.getDateNowNoFormat(-7),
        date_end: super.getDateNowNoFormat(7),
        expired: false,
        promocode: ''
    };

    ngOnInit() {
        this.loading.show();
        this.getPromocodes();
    }

    getPromocodes() {
        this.service.getPromocode(this.request).subscribe(
            data => {
                this.loading.hide();
                this.list = data as Promocode[];
            },
            error => super.onError(error));
    }

    onRemove(hash: string) {
        const self = this;
        super.showConfirmMessage('Atenção', 'Tem certeza que deseja remover esse cupom?',
            'warning', () => {
                self.remove(hash);
            });
    }

    remove(hash: string) {
        this.loading.show();
        this.service.delete(hash).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.getPromocodes();
                }

            },
            error => super.onError(error));
    }

    onEdit(id: string) {
        this.router.navigate(['/create-promocode', id]);
    }

}
