/**
 * Created by Igor on 11/05/2017.
 */
import {ProductComplementItemPrice} from "./product_complement_item_price";

export class ProductComplementItem {

    id: string;
    product_complement_id: string;
    name: string;
    description: string;
    status: string;
    order: number;
    prices: ProductComplementItemPrice[];

    constructor() {
        this.id = '';
        this.status = 'active';
        this.order = 0;
        this.prices = []
    }

}
