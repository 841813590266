import {Component} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {PromocodeService} from '../../../service/promocode.service';
import {isUndefined} from 'util';
import {Promocode} from '../../../model/promocode';
import {ResponseServer} from '../../../model/response_server';
import * as moment from 'moment';

@Component({
    selector: 'app-create-promocode',
    templateUrl: './create-promocode.component.html',
    styleUrls: ['./create-promocode.component.css']
})
export class CreatePromocodeComponent extends BaseComponent {

    constructor(public router: Router,
                public route: ActivatedRoute,
                public loading: NgxSpinnerService,
                public service: PromocodeService) {
        super(router, loading);

        this.route.params.subscribe(p => {
            if (!isUndefined(p['id'])) {
                this.loading.hide();
                this.getPromoCode(p['id']);
            } else {
                this.model.qtd = 0;
            }
        });
    }

    model: Promocode = new Promocode();

    getPromoCode(id: string) {
        this.service.getPromoCodeById(id).subscribe(
            data => {
                this.model = data as Promocode
                this.model.qtd = this.model.promocodes.length;
                this.model.expire = moment(this.model.expire).format('DD/MM/yyyy');
                this.loading.hide();
            },
            error => super.onError(error))
    }

    send() {
        if (this.isNullOrUndefined(this.model.promocode)) {
            super.showMessage('Atenção', 'Informe um código.');
            return;
        }

        if (this.isNullOrUndefined(this.model.qtd)) {
            super.showMessage('Atenção', 'A Quantidade do código de desconto está inválida');
            return;
        }

        if (this.isNullOrUndefined(this.model.discount_type)) {
            super.showMessage('Atenção', 'O tipo de desconto do código de desconto está inválida');
            return;
        }

        if (this.isNullOrUndefined(this.model.discount)) {
            super.showMessage('Atenção', 'O Desconto do código de desconto está inválida');
            return;
        }

        if (this.isNullOrUndefined(this.model.expire)) {
            super.showMessage('Atenção', 'A data de expiração está inválida');
            return;
        }

        if (this.model.discount_type === 'percentage' && this.model.discount > 100) {
            super.showMessage('Atenção', 'Porcentagem de desconto não pode ser maior que 100%' +
                '');
            return;
        }

        this.onSend();
    }

    onSend() {
        this.loading.show();
        this.service.send(this.model).subscribe(
            data => {
                const result = data as ResponseServer;
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.router.navigate(['/create-promocode', result.id]);
                }

            },
            error => super.onError(error));

    }

    addQtt(qtt: number) {
        this.model.qtd += qtt;
    }
}
