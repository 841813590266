import {User} from './user';
import {Order} from "./order";

export class Promocode {
    id: string;
    promocode: string;
    created_at: string;
    expire: string;
    discount_type: string;
    discount: number;
    qtt: number;
    qtd: number;
    available: number;
    user: User;
    promocodes: PromocodeOrder[];

    constructor() {
        this.promocodes = [];
    }
}

export class PromocodeOrder {
    id: string;
    used_at: Date;
    user_order_id: string;
    created_at: Date;
    order: Order;
}
