import {Establishment} from "./establishment";
import {Order} from "./order";
/**
 * Created by Igor on 24/05/2017.
 */
export class Comment {

  id: number;
  comment: string;
  name: string;
  rating: number;
  listStar: number[];
  establishment: Establishment;
  order: Order;
  created_at: string;
  answer: string;
  establishment_user_id: number;
  answer_date: string;
  photo: string;

  constructor() {
    this.rating = 0;
    this.listStar = [];
    this.establishment_user_id = 0;
  }
}
