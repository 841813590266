import {Component, OnInit} from '@angular/core';
import {Category} from '../../model/category';
import {CategoryService} from '../../service/category.service';
import {EstablishmentCategory} from '../../model/establishment-category';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {ResponseServer} from '../../model/response_server';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-category',
    templateUrl: './category.component.html',
    styleUrls: ['./category.component.css'],
    providers: [CategoryService]
})
export class CategoryComponent extends BaseComponent implements OnInit {

    modelCategory: Category = new Category();
    listCategory: EstablishmentCategory[] = [];
    pagerCategory: any = {};

    _editHash = '';

    requestCategory = {
        name: '',
        priority: 0
    };

    constructor(public categoryService: CategoryService,
                public router: Router,
                public loading: NgxSpinnerService) {
        super(router, loading);
    }

    ngOnInit() {
        this.getCategories();
    }

    getCategories() {
        this.loading.show();
        this.categoryService.getCategoryEstablishment().subscribe(
            data => {
                this.listCategory = data as EstablishmentCategory[];
                this.requestCategory.priority = this.listCategory.length;
                this.loading.hide();
                this.setPageCategory(1);
            },
            error => super.onError(error));
    }

    onSaveCategory() {
        this.loading.show();

        this.categoryService.create(this.requestCategory).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.requestCategory.name = '';
                }
                this.getCategories();
            },
            error => super.onError(error));
    }

    onEditCategory(hash: string) {
        this._editHash = hash;
    }

    onSaveRow(model) {
        this.categoryService.update(model).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.getCategories();
                    this._editHash = '';
                    this.requestCategory.name = '';
                }
            },
            error => super.onError(error));
    }

    updateStatusCategory(hash: string, status: boolean, statusString: string) {
        const parent = this;
        if (statusString === 'active') {
            super.showConfirmMessage('Atenção',
                'Fazendo isso, eu terei de pausar a venda de todos os itens desta categoria, tem certeza que deseja desativar?',
                'warning', () => {
                    parent.loading.show();
                    parent.categoryService.status(hash, status).subscribe(
                        data => {
                            const result = data as ResponseServer;
                            parent.loading.hide();
                            if (result.error) {
                                super.showMessage('Atenção', result.message, 'info');
                            } else {
                                parent.getCategories();
                            }

                        },
                        error => super.onError(error));
                });
        } else {
            parent.loading.show();
            parent.categoryService.status(hash, status).subscribe(
                data => {
                    const result = data as ResponseServer;
                    parent.loading.hide();
                    if (result.error) {
                        super.showMessage('Atenção', result.message, 'info');
                    } else {
                        parent.getCategories();
                    }

                },
                error => super.onError(error));
        }
    }

    onRemoveCategory(hash: string) {
        const self = this;
        super.showConfirmMessage(
            'Atenção',
            'Tem certeza que deseja remover essa categoria?',
            'warning', () => {
                self.removeCategory(hash);
            });
    }

    removeCategory(hash: string) {
        this.loading.show();
        this.categoryService.delete(hash).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.getCategories();
                }

            },
            error => super.onError(error));
    }

    setPageCategory(page: number) {
        this.pagerCategory = this.getPager(this.listCategory.length, page, 50);
    }

    getStatusLabel(status) {
        switch (status) {
            case 'active':
                return 'Ativo';
            default:
                return 'Inativo';
        }
    }

    getStatusClass(status) {
        switch (status) {
            case 'active':
                return 'label-success';
            default:
                return 'label-danger';
        }
    }

    Cancelar() {
        this._editHash = '';
        this.getCategories();
    }
}
