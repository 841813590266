import {OrderItem} from './order_item';
import {OrderStatusLog} from "./order-status-log";
import {User} from "./user";

export class Order {

    id: string;
    hash: string;
    name: string;
    ddd: number;
    phone: number;
    payment_hash: string;
    payment: string;
    address: string;
    district: string;
    city: string;
    state: string;
    complement: string;
    date: string;
    address_number: number;
    total: number;
    subtotal: number;
    discount: number;
    exchange_for: number;
    items: [OrderItem];
    status: string;
    saalve_bag_id: string;
    saalve_status: string;
    saalve_url_follow: string;
    driver_id: number;
    observation: string;
    delivery_price: number;
    cancel_option_id: string;
    time: string;
    cancel_option_description: string;
    is_desk: number;
    zip_code: string;
    user: User;

    constructor() {
    }
}
