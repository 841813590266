/**
 * Created by vagnerss on 29/05/17.
 */

import {Response, RequestOptions} from '@angular/http';
import {Settings} from '../settings';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

export class ServiceBase {

  public BASE_URL = Settings.BASE_URL;
  public DEBUG = '?XDEBUG_SESSION_START=PHPSTORM';

  constructor(public  http: HttpClient) {
  }

  public getToken() {
    return localStorage.getItem('token') as String;
  }

  public header() {
    const _headers: HttpHeaders = new HttpHeaders();
    _headers.append('Content-Type', 'application/json');

    const token = localStorage.getItem('token') as String;

    if (token != null) {
      _headers.append('Authorization', 'Bearer ' + token);
    }
    return {headers: _headers};
  }

  public onError(error: Response | any) {
    console.error(error);
    return Observable.throw(error.message || error);
  }

  public extractData(res: Response) {
    const body = res.json();
    return body || {};
  }

}
