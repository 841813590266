import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Product} from '../../model/product';
import {ProductService} from '../../service/product.service';
import {isNullOrUndefined, isUndefined} from 'util';
import {EstablishmentCategory} from '../../model/establishment-category';
import {CategoryService} from '../../service/category.service';
import {Settings} from '../../settings';
import {ResponseServer} from '../../model/response_server';
import {UploadService} from '../../service/upload.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Subject} from 'rxjs/index';
import {ImageCroppedEvent, ImageCropperComponent} from 'ngx-image-cropper';

@Component({
    selector: 'app-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-detail.component.css'],
    providers: [ProductService, CategoryService, UploadService]
})
export class ProductDetailComponent extends BaseComponent {

    tabIndex = 0;
    model: Product = new Product();
    listCategory: EstablishmentCategory[] = [];
    flagSelectAll = false;

    _thumpPath = 'assets/pages/img/nophoto.png';

    flagStatus: boolean;

    changer: boolean;

    file: File = null;

    initsize = false;
    initavailability = false;
    initcomplement = false;
    initpromotion = false;

    changingValue: Subject<boolean> = new Subject();
    @ViewChild(ImageCropperComponent)
    imageCropper: ImageCropperComponent;
    imageChangedEvent: any = '';
    croppedImage: any = '';

    constructor(public router: Router,
                public route: ActivatedRoute,
                public service: ProductService,
                public loading: NgxSpinnerService,
                public categoryservice: CategoryService,
                public uploadservice: UploadService) {
        super(router, loading);
        this.model = new Product();
        this.service.productData.subscribe(
            data => {
                if (!isNullOrUndefined(this.model.id)) {
                    this.getProduct(this.model.id);
                }
            });
        this.getCategories();
        this.route.params.subscribe(p => {
            if (isUndefined(p['id'])) {
            } else {
                this.model.id = p['id'];
                this.getProduct(p['id']);
            }
        });
    }

    onChange() {
        const self = this;
        if (!isUndefined(this.model.id)) {
            super.showConfirmMessage(
                'Atenção',
                'Tem certeza que deseja alterar o tipo de preço?',
                'warning',
                () => {
                    self.onUpdate(self.model);
                });
        }

    }

    getProduct(hash: string) {
        this.loading.show();
        this.service.getProduct(hash).subscribe(
            data => {
                this.model = data as Product;
                if (this.model.status === 'active') {
                    this.flagStatus = true;
                } else {
                    this.flagStatus = false;
                }
                if (this.model.photo.length !== 0) {
                    this._thumpPath = Settings.BASE_URL + '/image/' + this.model.photo + '?nocache=' + Date.now();
                }

                this.loading.hide();
            },
            error => super.onError(error));
    }

    onSubmitSave() {

        if (typeof this.model.name === 'undefined') {
            super.showMessage('Atenção', 'Insira o Nome do Produto', 'info');
            return;
        }

        /*if (typeof this.model.code === 'undefined') {
            super.showMessage('Atenção', 'Insira o Código do  Produto', 'error');
            return;
        }*/

        if (typeof this.model.price === 'undefined') {
            this.model.price = 0;
        }

        if (typeof this.model.discount === 'undefined') {
            this.model.discount = 0;
        }

        if (typeof this.model.flavor_count === 'undefined') {
            this.model.flavor_count = 0;
        } else if (this.model.flavor_count < 0) {
            super.showMessage(
                'Atenção',
                'A quantidade de sabores selecionáveis deve maior ou igual a zero.', 'info');
            return;
        }

        if (typeof this.model.establishment_category_id === 'undefined') {
            super.showMessage('Atenção', 'Selecione uma Categoria', 'info');
            return;
        }

        this.loading.show();

        if (this.model.id !== undefined) {
            this.onUpdate(this.model);
            return;
        }

        this.service.create(this.model).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.model = data as Product;
                    if (!isNullOrUndefined(this.file)) {
                        this.uploadImage();
                    } else {
                        window.history.replaceState({pagina: 1}, 'teste', '/product/detail/' + this.model.id);
                        this.router.navigate(['/product/detail/', this.model.id]);
                    }
                }
            },
            error => super.onError(error));
    }

    onUpdate(model: Product) {
        this.loading.show();
        this.service.update(model).subscribe(
            data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.model = data as Product;
                    if (!isNullOrUndefined(this.file)) {
                        this.uploadImage();
                    }
                }
            },
            error => super.onError(error));
    }

    onChangeProductStatus() {
        this.loading.show();
        if (this.model.status === 'inactive') {
            this.service.update(this.model).subscribe(
                data => {
                    const result = data as ResponseServer;
                    if (result.error) {
                        super.showMessage('Atenção', result.message, 'info');
                        return;
                    } else {
                        this.model = data as Product;
                        if (!isNullOrUndefined(this.file)) {
                            this.uploadImage(true);
                        } else {
                            this.updateProductStatus();
                            this.getCategories();
                            this.route.params.subscribe(p => {
                                if (isUndefined(p['id'])) {
                                } else {
                                    this.getProduct(p['id']);
                                }
                            });
                        }
                    }
                },
                error => super.onError(error));
        } else {
            this.updateProductStatus();
        }

    }

    updateProductStatus() {
        this.service.status(this.model.id).subscribe(
            data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.getProduct(this.model.id);
                }
            },
            error => super.onError(error));
    }

    selectAllDays() {
        this.flagSelectAll = !this.flagSelectAll;
        this.model.monday = this.flagSelectAll;
        this.model.tuesday = this.flagSelectAll;
        this.model.wednesday = this.flagSelectAll;
        this.model.thursday = this.flagSelectAll;
        this.model.friday = this.flagSelectAll;
        this.model.saturday = this.flagSelectAll;
        this.model.sunday = this.flagSelectAll;
    }

    clickLabelDay(day: boolean) {
        day = !day;
    }

    uploadImage(isFromChangeStatus: boolean = false) {
        this.uploadservice.uploadProduct(this.file, this.model.id).subscribe(
            data => {
                const result = data as ResponseServer;
                if (!isFromChangeStatus) {
                    this.loading.hide();
                    if (result.error) {
                        super.showMessage('Atenção', result.message, 'info');
                    } else {
                        this.model.photo = data;
                        this.router.navigate(['/product/detail/', this.model.id]);
                    }
                } else {
                    this.updateProductStatus();
                }
            },
            error => super.onError(error));
    }

    getCategories() {
        this.loading.show();
        this.categoryservice.getCategory().subscribe(
            data => {
                this.listCategory = data as EstablishmentCategory[];
                this.loading.hide();
            },
            error => super.onError(error));
    }

    handleUpload(event) {
        const files = event.srcElement.files;
        if (files.length > 0) {
            if (files[0].size > 2000000) {
                super.showMessage(
                    'Atenção',
                    'A foto não deve ultrapassar o tamanho de 2MB', 'info');
                return;
            }
            if (files[0].type.indexOf('jpeg') === -1 && files[0].type.indexOf('png') === -1) {
                super.showMessage('Atenção',
                    'Só será permitida fotos com extensões .jpg ou .png', 'info');
                return;
            }
        }
        this.fileChangeEvent(event);
        /*const reader = new FileReader();
        reader.onload = (_event) => {
            this._thumpPath = _event.target['result'];
        };
        reader.readAsDataURL(event.target.files[0]);

        this.file = files[0];*/

        super.onShowModal('#cropImage');
    }

    initBasicdata() {
        this.tabIndex = 0;
    }

    initSize() {
        this.tabIndex = 2;
        this.initsize = true;
    }

    initAvailability() {
        this.tabIndex = 1;
        this.initavailability = true;
    }

    initComplement() {
        this.tabIndex = 4;
        this.initcomplement = true;
        this.initsize = true;
        this.tellChild();
    }

    initPromotion() {
        this.tabIndex = 5;
        this.initpromotion = true;
    }

    onChangePriceType(type) {
        this.model.price_type = type;
        this.onUpdate(this.model);
    }

    tellChild() {
        this.changingValue.next(true);
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        console.log(event);
        this.croppedImage = event.base64;
        this._thumpPath = this.croppedImage;
        this.file = this.convertBase64ToBlob(event.base64);
    }

    convertBase64ToBlob(base64Image: string) {
        // Split into two parts
        const parts = base64Image.split(';base64,');

        // Hold the content type
        const imageType = parts[0].split(':')[1];

        // Decode Base64 string
        const decodedData = window.atob(parts[1]);

        // Create UNIT8ARRAY of size same as row data length
        const uInt8Array = new Uint8Array(decodedData.length);

        // Insert all character code into uInt8Array
        for (let i = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }

        return new File([uInt8Array], 'cropped', {type: imageType});
    }

    crop() {
        this.imageCropper.crop();
        super.onCloseModal('#cropImage');
    }
}
