import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {OrderService} from '../../service/order.service';
import {PaymentService} from '../../service/payment.service';
import {Payment} from '../../model/payment';
import {Order} from '../../model/order';
import {Extract} from '../../model/extract';
import {Plan} from '../../model/plan';
import {PlanService} from '../../service/plan.service';
import {ResponseServer} from '../../model/response_server';
import {isNullOrUndefined} from 'util';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-extract',
    templateUrl: './extract.component.html',
    styleUrls: ['./extract.component.css'],
    providers: [OrderService, PaymentService, PlanService]
})
export class ExtractComponent extends BaseComponent implements OnInit {

    year: number = super.getYear();
    listPlan: Plan[] = [];
    listPayment: Payment[] = [];
    model: Extract[] = [];
    statusPayment = 0;

    modelPlan: Plan = new Plan();

    checkPaymentForm: string;

    flag: boolean;

    card = {
        type_payment: 'card',
        card_month: null,
        card_year: null,
        card_number: null,
        card_name: '',
        card_code: null
    };

    boleto = {
        type_payment: 'bill'
    };

    paymentNow = {
        flag: '',
        number: ''
    };

    request = {
        m: 0,
        y: 0,
    };


    constructor(public router: Router,
                public orderservice: OrderService,
                public paymentservice: PaymentService,
                public servicePlan: PlanService,
                public loading: NgxSpinnerService) {
        super(router, loading);
    }

    ngOnInit() {
        this.getList();
    }

    getList() {
        this.loading.show();

        this.request.y = this.year;
        this.orderservice.getOrderExtract(this.request).subscribe(
            data => {
                this.loading.hide();
                this.model = data['extract'] as Extract[];
                this.modelPlan = data['plan'] as Plan;
            },
            error => super.onError(error));
    }

    getPayment() {
        this.loading.show();
        this.paymentservice.getPaymentForm().subscribe(
            data => {
                this.loading.hide();
                this.paymentNow.flag = data['flag'];
                this.paymentNow.number = data['number'];
            },
            error => super.onError(error));
    }

    getListPlan() {
        this.servicePlan.getPlan().subscribe(
            data => {
                this.listPlan = data as Plan[];
            },
            error => super.onError(error));
    }

    onChangeSelectedPlan(event) {
        // this.modelPlan = new Plan();
        this.flag = true;
        this.modelPlan = this.listPlan.find(value => value.id === event);

    }

    onChangePlan() {
        this.flag = false;
        this.getListPlan();
        super.onShowModal('#plan');
    }

    onChangePayment() {
        this.getPayment();
        this.checkPaymentForm = 'now';
        super.onShowModal('#payment');
    }

    getStatusLabel(status) {
        switch (status) {
            case 'using':
                return 'Em uso';
            case 'overflow':
                return 'Excedido';
            case 'paid':
                return 'Pago';
            case 'panding':
                return 'Pendente';
            default:
                return '';
        }
    }

    getStatusClass(status) {
        switch (status) {
            case 'using':
                return 'label-info';
            case 'orverflow':
                return 'label-danger';
            case 'paid':
                return 'label-success';
            case 'panding':
                return 'label-warning';
            default:
                return '';
        }
    }

    getTypeLabel(type) {
        switch (type) {
            case 'promo':
                return 'Promoção';
            case 'bought':
                return 'Adquirido';
            case 'initial':
                return 'Inicial';
            default:
                return 'Inativo';
        }
    }

    getTypeClass(type) {
        switch (type) {
            case 'promo':
                return 'label-info';
            case 'bought':
                return 'label-success';
            case 'initial':
                return 'label-warning';
            default:
                return 'label-danger';
        }
    }

    changePayment() {
        if (this.checkPaymentForm === 'card') {
            if (isNullOrUndefined(this.card.card_code) || this.card.card_code === '') {
                super.showMessage('Atenção', 'Informe o código.', 'info');
                return true;
            }
            if (isNullOrUndefined(this.card.card_month) || this.card.card_month === '') {
                super.showMessage('Atenção', 'Informe o mês de vencimento.', 'info');
                return true;
            }
            if (isNullOrUndefined(this.card.card_year) || this.card.card_year === '') {
                super.showMessage('Atenção', 'Informe o ano de vencimento.', 'info');
                return true;
            }
            if (isNullOrUndefined(this.card.card_name) || this.card.card_name === '') {
                super.showMessage('Atenção', 'Informe o nome do titular.', 'info');
                return true;
            }
            if (isNullOrUndefined(this.card.card_number) || this.card.card_number === '') {
                super.showMessage('Atenção', 'Informe o número do cartão.', 'info');
                return true;
            }
            this.loading.show();
            this.paymentservice.changePayment(this.card).subscribe(data => {
                    const result = data as ResponseServer;
                    this.loading.hide();
                    if (result.error) {
                        super.showMessage('Atenção', result.message, 'info');
                    } else {
                        super.onCloseModal('#payment');
                    }
                },
                error => super.onError(error));

        } else if (this.checkPaymentForm === 'bill') {
            this.loading.show();
            this.paymentservice.changePayment(this.boleto).subscribe(data => {
                    const result = data as ResponseServer;
                    this.loading.hide();
                    if (result.error) {
                        super.showMessage('Atenção', result.message, 'info');
                    } else {
                        super.onCloseModal('#payment');
                    }
                },
                error => super.onError(error));

        } else if (this.checkPaymentForm === 'mp') {
            // todo verificação se o status do mp é diferente de ok para chamar a rota
            this.loading.show();
            this.paymentservice.changePayment(this.boleto).subscribe(data => {
                    const result = data as ResponseServer;
                    this.loading.hide();
                    if (result.error) {
                        super.showMessage('Atenção', result.message, 'info');
                    } else {
                        super.onCloseModal('#payment');
                    }
                },
                error => super.onError(error));

        }


    }

    changePlan() {
        console.log(this.modelPlan.id);
        this.loading.show();
        this.servicePlan.changePlan(this.modelPlan.id).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    super.onCloseModal('#plan');
                }
            },
            error => super.onError(error));

    }

}
