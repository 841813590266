import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-term-app',
  templateUrl: './term-app.component.html',
  styleUrls: ['./term-app.component.css']
})
export class TermAppComponent implements OnInit {

    flag: boolean;

  constructor() { }

  ngOnInit() {
  }

}
