///<reference path="service-base.ts"/>
///<reference path="../../../node_modules/@angular/http/src/http.d.ts"/>
import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {Http, Response} from '@angular/http';
import {Observable} from 'rxjs';
import {User} from '../model/user';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable()
export class UserService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    login(email: string, password: string, type: string) {
        const url = `${this.BASE_URL}/api/auth/login`;
        const data = {email: email, password: password, type: type};
        return this.http.post(url, JSON.stringify(data));
    }

    forgot(data) {
        const url = `${this.BASE_URL}/api/auth/forgot`;
        return this.http.post(url, data);
    }

    changepassword(data) {
        const url = `${this.BASE_URL}/api/auth/password`;
        return this.http.post(url, data);
    }

    getUsers(term: string) {
        let url = `${this.BASE_URL}/api/establishment/user/${term}`;
        return this.http.get(url);
    }

    delete(id: string) {
        const url = `${this.BASE_URL}/api/auth/remove/${id}`;
        return this.http.delete(url);
    }

    deleteEmployee(id: string) {
        const url = `${this.BASE_URL}/api/establishment/user/${id}`;
        return this.http.delete(url);
    }

    create(model: User) {
        const url = `${this.BASE_URL}/api/auth/register/admin`;
        return this.http.post(url, model);
    }

    createEmployee(model: User) {
        const url = `${this.BASE_URL}/api/auth/register/establishment/user`;
        return this.http.post(url, model);
    }

    update(model: User) {
        const url = `${this.BASE_URL}/api/auth/user/update`;
        return this.http.put(url, model);
    }

    updateEmployee(model: User) {
        const url = `${this.BASE_URL}/api/establishment/user`;
        return this.http.put(url, model);
    }

    connect(idEstablishiment: string, idUser: string) {
        const url = `${this.BASE_URL}/api/establishment/relate`;
        const data = {user_id: idUser, establishment_id: idEstablishiment};
        return this.http.post(url, data);
    }

}
