export class AttendenceItem {

    id: number;
    attendance_day_id: string;
    attendance: string;
    time_start: string;
    time_end: string;
    hash: string;

    constructor() {
    }
}

export class AttendenceItemList {

    attendance: string;
    schedules: [{time_start: string, time_end: string, hash: string}];

    constructor() {
    }
}