import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {OrderService} from '../../service/order.service';
import {Order} from '../../model/order';
import {User} from '../../model/user';
import {Subscription} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import {GraphService} from '../../service/graph.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    providers: [OrderService, GraphService]
})

export class HomeComponent extends BaseComponent implements OnInit {

    date = '';

    listDriver: User[] = [];
    listOrder: Order[] = [];
    establishment = super.getUser().establishment;

    model: Order = new Order();
    pager: any = {};

    request = {
        date_start: super.getDateNowNoFormat(-1),
        date_end: super.getDateNowNoFormat(),
        status: 0
    };

    sub: Subscription;

    requestDaily = {
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
    };

    dashboard = {
        orders_today: 0,
        amount_today: 0,
        orders_month: 0,
        amount_month: 0,
        orders_count_average: 0,
        orders_amount_average: 0,
        evaluation_average: 0,
        evaluation_qtt: 0,
        ticket: 0
    };

    years = [];

    constructor(public router: Router,
                public graphService: GraphService,
                public loading: NgxSpinnerService,
                public service: OrderService) {
        super(router, loading);
        for (let i = new Date().getFullYear(); i >= 2021; i--) {
            this.years.push(i);
        }
        this.getChartEvolutionDailyOrder();
    }

    ngOnInit() {
        this.getDashboard();
    }

    getChartEvolutionDailyOrder() {
        this.date = this.requestDaily.month + '/' + this.requestDaily.year;
        this.loading.show();
        this.graphService.getChartEvolutionDailyOrder(this.date).subscribe(
            data => {
                Chart.chartEvolutionDailyOrder(data);
                this.loading.hide();
            }, error => super.onError(error));
    }

    getDashboard() {
        this.graphService.getDashboard().subscribe(
            data => {
                this.dashboard = data as any;
                this.dashboard.ticket =
                    this.dashboard.orders_amount_average /
                    (this.dashboard.orders_count_average === 0 ? 1 : this.dashboard.orders_count_average);
                this.loading.hide();
            }, error => super.onError(error));
    }


}
