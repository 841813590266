import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {Http} from '@angular/http';
import {Payment} from '../model/payment';
import {HttpClient} from '@angular/common/http';

/**
 * Created by Igor on 09/05/2017.
 */
@Injectable()
export class PaymentService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getPayment() {
        const url = `${this.BASE_URL}/api/establishment/payment/list/all`;
        return this.http.get(url);
    }

    getPaymentEstablishment() {
        const url = `${this.BASE_URL}/api/establishment/payment/list`;
        return this.http.get(url);
    }

    getPaymentForm() {
        const url = `${this.BASE_URL}/api/establishment/plan/card`;
        return this.http.get(url);
    }

    create(model) {
        const url = `${this.BASE_URL}/api/establishment/payment/relate`;
        return this.http.post(url, model);
    }

    changePayment(model) {
        const url = `${this.BASE_URL}/api/establishment/plan/changepayment`;
        return this.http.post(url, model);
    }

    delete(model) {
        const url = `${this.BASE_URL}/api/establishment/payment/relate`;
        return this.http.post(url, model);
    }

    mpConnect() {
        const url = `${this.BASE_URL}/api/mp/authorize`;
        return this.http.post(url, null);
    }

    enableOnlinePayment(id) {
        const url = `${this.BASE_URL}/api/external/checkout-success-online-payment/${id}`;
        return this.http.post(url, '{}');
    }

    disableOnlinePayment() {
        const url = `${this.BASE_URL}/api/establishment/payment/disable-online`;
        return this.http.delete(url);
    }
}
