import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {HttpClient} from '@angular/common/http';

/**
 * Created by Igor on 11/05/2017.
 */
@Injectable()
export class GraphService extends ServiceBase {
    constructor(http: HttpClient) {
        super(http);
    }

    getChartDeliveryDay() {
        const url = `${this.BASE_URL}/api/establishment/report/order/delivered`;
        return this.http.get(url);
    }

    getChartDeliveryZone() {
        const url = `${this.BASE_URL}/api/establishment/report/order/region`;
        return this.http.get(url);
    }

    getChartOrder(data) {
        const url = `${this.BASE_URL}/api/establishment/report/order/${data}`;
        return this.http.get(url);
    }

    getChartPayment() {
        const url = `${this.BASE_URL}/api/establishment/report/financial/payment`;
        return this.http.get(url);
    }

    getChartPreparing(data) {
        const url = `${this.BASE_URL}/api/establishment/report/order/${data}`;
        return this.http.get(url);
    }

    getChartTicket(data) {
        const url = `${this.BASE_URL}/api/establishment/report/order/${data}`;
        return this.http.get(url);
    }

    getChartEvolutionDailyOrder(data) {
        const url = `${this.BASE_URL}/api/establishment/report/order/daily/${data}`;
        return this.http.get(url);
    }

    getChartEvolutionDailyRevenues(data) {
        const url = `${this.BASE_URL}/api/establishment/report/financial/daily/${data}`;
        return this.http.get(url);
    }

    getChartOrderMonth() {
        const url = `${this.BASE_URL}/api/establishment/report/order/brief/`;
        return this.http.get(url);
    }

    getChartRevenuesMonth() {
        const url = `${this.BASE_URL}/api/establishment/report/financial/brief`;
        return this.http.get(url);
    }

    getChartEvolutionOrder(data) {
        const url = `${this.BASE_URL}/api/establishment/report/order/quarterly/${data}`;
        return this.http.get(url);
    }

    getChartEvolutionRevenues(data) {
        const url = `${this.BASE_URL}/api/establishment/report/financial/quarterly/${data}`;
        return this.http.get(url);
    }

    getDashboard() {
        const url = `${this.BASE_URL}/api/establishment/dashboard`;
        return this.http.get(url);
    }
}
