import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {isUndefined} from "util";
import {Settings} from "../settings";

@Injectable()
export class HeaderHttpInterceptor implements HttpInterceptor {
    constructor() {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let _headers = new HttpHeaders();
        _headers = _headers.append('Content-Type', 'application/json');

        const token = localStorage.getItem('token')  as String;

        if (token != 'undefined' && req.url.includes(Settings.BASE_URL)) {
            _headers = _headers.append('Authorization', 'Bearer ' + token);
        }

        let authReq = req.clone(
            {
                headers: _headers,
            }
        );

        return next.handle(authReq);
    }
}
