import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {GraphService} from '../../service/graph.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-graphic-performance',
  templateUrl: './graphic-performance.component.html',
  styleUrls: ['./graphic-performance.component.css'],
    providers: [GraphService]
})
export class GraphicPerformanceComponent extends BaseComponent implements OnInit {

    date = this.getDateNowNoFormat();

    requestPreparing = {
        month: '11',
        year: '2018'
    };

    requestTicket = {
        month: '11',
        year: '2018'
    };

    constructor(public router: Router,
                public service: GraphService,
                public loading: NgxSpinnerService) {
        super(router, loading);
    }

    ngOnInit() {
        // this.getChartOrder();
        this.getChartPayment();
        // this.getChartPreparing();
        // this.getChartTicket();
    }

    public getChartOrder() {
        this.loading.show();
        this.service.getChartOrder(this.date).subscribe(
            data => {
                this.loading.hide();
                Chart.chartOrder(data);
            },
            error => super.onError(error));
    }

    public getChartPayment() {
        this.loading.show();
        this.service.getChartPayment().subscribe(
            data => {
                this.loading.hide();
                Chart.chartPayment(data);
            },
            error => super.onError(error));
    }

    public getChartPreparing() {
        this.date = this.requestPreparing.month + '/' + this.requestPreparing.year;
        this.loading.show();
        this.service.getChartPreparing(this.date).subscribe(
            data => {
                this.loading.hide();
                Chart.chartPreparing(data);
            },
            error => super.onError(error));
    }

    public getChartTicket() {
        this.date = this.requestTicket.month + '/' + this.requestTicket.year;
        this.loading.show();
        this.service.getChartTicket(this.date).subscribe(
            data => {
                this.loading.hide();
                Chart.chartTicket(data);
            },
            error => super.onError(error));
    }

}
