import {Component, HostListener, OnInit} from '@angular/core';
import '../../base/extends';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {UserService} from '../../service/user.service';
import {EstablishmentService} from '../../service/establishment.service';
import {User} from '../../model/user';
import {PushService} from '../../service/push.service';
import {ResponseServer} from '../../model/response_server';
import {Settings} from '../../settings';
import {NgxSpinnerService} from 'ngx-spinner';
import {Att, OpenClose} from '../../model/open-close';
import {isNullOrUndefined} from 'util';

declare const jquery: any;
declare const $: any;

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.css'],
    providers: [EstablishmentService, PushService]
})
export class MainComponent extends BaseComponent implements OnInit {

    notification_count = 0;

    keyword = 'name';
    data = [
        {
            id: '/order',
            name: 'Pedidos - Operação'
        },
        {
            id: '/comments',
            name: 'Comentários'
        },
        {
            id: '/comments',
            name: 'Avaliações'
        },
        {
            id: '/category',
            name: 'Categoria'
        },
        {
            id: '/menu',
            name: 'Itens'
        },
        {
            id: '/menu',
            name: 'Produtos'
        },
        {
            id: '/promocode',
            name: 'Cupom de desconto'
        },
        {
            id: '/extract',
            name: 'Extrato - Estatísticas'
        },
        {
            id: '/graphic-performance',
            name: 'Desempenho - Estatísticas'
        },
        {
            id: '/graphic-delivery',
            name: 'Entregas - Estatísticas'
        },
        {
            id: '/graphic-order',
            name: 'Pedidos - Estatísticas'
        },
        {
            id: '/graphic-revenues',
            name: 'Faturamento - Estatísticas'
        },
        {
            id: '/establishment',
            name: 'Dados Básicos - Configurações'
        },
        {
            id: '/establishment',
            name: 'Estimativa de Entrega - Configurações'
        },
        {
            id: '/establishment',
            name: 'Telefone - Configurações'
        },
        {
            id: '/establishment',
            name: 'Foto - Configurações'
        },
        {
            id: '/establishment/establishment-attendence',
            name: 'Atendimento - Configurações'
        },
        {
            id: '/establishment/establishment-attendence',
            name: 'Turnos - Configurações'
        },
        {
            id: '/user',
            name: 'Usuários - Configurações'
        },
        {
            id: '/payment',
            name: 'Formas de Pagamento - Configurações'
        },
        {
            id: '/map',
            name: 'Delivery - Configurações'
        },
        {
            id: '/map',
            name: 'Areas de Entrega - Configurações'
        },
        {
            id: '/map',
            name: 'Mapa - Configurações'
        },
        {
            id: '/map',
            name: 'Preço de entrega - Configurações'
        }
    ];

    status = {
        description: 'Fechado',
        cssClass: 'label-warning',
        icon: 'fa-times'
    };

    listNovelty = [];
    version: string;

    order = 75;

    config = {
        orders: 0,
        plan: '',
        status: '',
        used: ''
    };

    request = {
        old_password: '',
        password: '',
        repassword: ''
    };

    flag: number;
    initdashboard: false;

    attendance = '';

    constructor(public router: Router,
                public serviceestablishment: EstablishmentService,
                public userservice: UserService,
                public pushservice: PushService,
                public loading: NgxSpinnerService) {
        super(router, loading);
    }

    @HostListener("window:resize", [])
    private onResize(event?) {
        if (window.innerWidth < 991) {
            this.disableMenu()
        }
    }

    ngOnInit() {
        // remove background login
        console.log(this.router.url);
        if (window.innerWidth < 991) {
            this.disableMenu()
        }
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('background-login');
        this.version = Settings.VERSION;
        this.getConfig();
        // const body = document.getElementsByTagName('body')[0];
        // body.style.background = '#ff5c00';
        this.loggedUser = super.getUser();
        this.onPushOrder();
        if (!isNullOrUndefined(this.loggedUser.establishment.photo) && this.loggedUser.establishment.photo.length > 0) {
            return;
        } else {
            this.loggedUser.establishment.photo = 'assets/pages/img/nophoto.png';
        }
    }

    get() {
        this.loading.show();
        this.serviceestablishment.getEstablishment().subscribe(
            data => {
                this.loggedUser = data as User;
                super.setUser(this.loggedUser);
                this.loading.hide();
            },
            error => super.onError(error)
        );
    }

    selectEvent(item) {
        this.router.navigate([item['id']]);
    }

    onChangeSearch(val: string) {
        // fetch remote data from here
        // And reassign the 'data' which is binded to 'data' property.
    }

    onFocused(e) {
        // do something when input is focused
    }

    onPushOrder() {
        const parent = this;
        super.onPusher(
            this.loggedUser.establishment.id,
            this.loggedUser.token,
            Settings.PUSHER_CLUSTER,
            Settings.PUSHER_END_POINT,
            Settings.PUSHER_KEY,
            function (confirm) {
                parent.getConfig();
                const a = document.getElementById('player') as HTMLMediaElement;
                a.play();
                parent.showMessage('Atenção', 'Tem pedido novo na área', 'warning', () => {
                    a.pause();
                    a.currentTime = 0;
                    parent.router.navigate(['/order']);
                });
            });
    }

    back() {
        window.history.back();
    }

    Tips() {
        this.router.navigate(['/tips']);
    }

    clearNovelty() {
        this.listNovelty = [];
        this.notification_count = 0;
        localStorage.setItem('listNovelty', JSON.stringify(this.listNovelty));
        localStorage.setItem('notification_count', JSON.stringify(this.notification_count));
    }

    controlTime(dateNovelty) {
        const date = new Date(dateNovelty);
        const today = new Date();
        const dateDiff = (today.getTime() - date.getTime());
        const min = Math.round((dateDiff / 60000) % 60);
        const hr = Math.round(min / 60);
        const day = Math.round(hr / 12);
        if (min > 60) {
            return 'Há ' + hr + ' horas';
        } else if (hr > 12) {
            return 'Há ' + day + ' dias';
        } else if (min < 1) {
            return 'Agora';
        } else {
            return 'Há ' + min + ' minutos';
        }
    }

    redirectComponent(type) {
        switch (type) {
            case 0:
                this.router.navigate(['/home']);
                break;
            case 1:
                this.router.navigate(['/home/comments']);
                break;
        }

        if (this.notification_count > 0) {
            this.notification_count -= 1;
            localStorage.setItem('notification_count', JSON.stringify(this.notification_count));
        }
    }

    openModalPassword() {
        super.onShowModal('#changePassword');
    }

    onValidateNewPassword() {

        if (this.request.password !== this.request.repassword) {
            super.showMessage('Atenção', 'As senhas não são idênticas', 'info');
            return;
        }

        if (this.request.password.length < 6) {
            super.showMessage('Atenção', 'Sua senha deve conter no mínimo 6 caracteres', 'info');
            return;
        }

        this.loading.show();
        this.userservice.changepassword(this.request).subscribe(
            data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    super.onCloseModal('#changePassword');

                }
            },
            error => super.onError(error));
    }

    changeStatusClassAndDescription(result: Att) {
        this.status.description = result.status === 'open' ? 'Aberto' : 'Fechado';
        this.status.cssClass = result.status === 'open' ? 'label-success' : 'label-warning';
        this.status.icon = result.status === 'open' ? 'fa-check' : 'fa-times ml';

        if (result.in == null) {
            return;
        }

        if (result.in) {
            if (result.status === 'open') {
                this.attendance = `Das: ${result.time_start} / ${result.time_end}`;
            } else {
                this.attendance = 'Dentro do horário de atendimento';
            }
        } else {
            if (result.status === 'open') {
                this.attendance = `Fora do horário de atendimento`;
            } else {
                this.attendance = `Abrirá automaticamente às: ${result.time_start}`;
            }
        }
    }

    getConfig() {
        this.loading.show();
        this.serviceestablishment.getEstablishmentConfig().subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.config.used = data['used'];
                    this.config.orders = data['orders'];
                    this.loggedUser.establishment.status = data['status'];
                    this.changeStatusClassAndDescription(result.current);
                }
            },
            error => super.onError(error));
    }

    onChangeStatus(update: boolean) {
        this.loading.show();
        this.serviceestablishment.updateStatus(this.loggedUser.establishment.id, update).subscribe(data => {
                const result = data as OpenClose;
                this.loading.hide();
                this.changeStatusClassAndDescription(result.current);
                super.setUser(this.loggedUser);
            },
            error => super.onError(error));
    }

    changeStatus(status: string) {
        switch (status) {
            case 'active':
                return 'label-success';
            default:
                return 'label-warning';
        }
    }

    changeTab(tab) {
        this.flag = tab;
    }

    onLogout() {
        localStorage.clear();
        this.router.navigate(['/login']);
        super.onCloseModal('#changepassword');
    }

    User() {
        this.router.navigate(['/user']);
    }

    Map() {
        this.router.navigate(['/map']);
    }

    toggleMenuView(cls: string) {
        if (!document.getElementById('logo').classList.contains('logo')) {
            $(cls).toggleClass('show');
        }
    }

    toggleBody() {
        $('body').toggleClass('sidebar-toggled');
        $('.sidebar').toggleClass('toggled');
        if ($('.sidebar').hasClass('toggled')) {
            $('.sidebar .collapse').collapse('hide');
        }
        $('#logo').toggleClass('logo');
        $('#content-wrapper').toggleClass('body-menu-toggle');
        $('#top-bar').toggleClass('body-menu-toggle');
    }

    home() {
        this.router.navigate(['/home/']);
    }

    actionMenu() {
        $('#accordionSidebar').toggleClass('disabled');
    }

    disableMenu() {
        $('#accordionSidebar').addClass('toggled');
        $('#logo').removeClass('logo');
        $('#content-wrapper').removeClass('body-menu-toggle');
        $('#top-bar').removeClass('body-menu-toggle');
    }
}
