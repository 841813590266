import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ResponseServer} from '../../model/response_server';
import {EstablishmentService} from '../../service/establishment.service';
import {AddressService} from '../../service/address.service';
import {State} from '../../model/state';
import {City} from '../../model/city';
import {District} from '../../model/district';
import {User} from '../../model/user';
import {CategoryService} from '../../service/category.service';
import {Category} from '../../model/category';
import {UserService} from '../../service/user.service';
import {Register} from '../../model/register';
import {debug, isNullOrUndefined} from 'util';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css'],
    providers: [EstablishmentService, AddressService, CategoryService, UserService]
})
export class RegisterComponent extends BaseComponent implements OnInit {

    model: Register = new Register();
    modelUser: User = new User();

    password_confirm: string;

    validTemplate: boolean;

    section = 0;

    listStates: State[] = [];
    listCity: City[] = [];
    listDistricts: District[] = [];

    listCategory: Category[] = [];

    listTitle = [
        {title: 'Complete seu cadastro', subtitle: 'Preencha as informações para cadastrar o seu estabelecimento.'},
        {title: 'Informe o endereço', subtitle: 'Agora preciso saber o endereço do seu estabelecimento.'},
        {title: 'Dados do contato', subtitle: 'Parabéns, estamos quase lá!'}
    ];

    constructor(public router: Router,
                public route2: ActivatedRoute,
                public service: EstablishmentService,
                public addressservice: AddressService,
                public categoryservice: CategoryService,
                public userservice: UserService,
                public loading: NgxSpinnerService) {
        super(router, loading);
    }

    ngOnInit() {
        this.model = new Register();
        // this.modelUser = super.getUser();
        this.getState();
        this.getCategory();

        this.validTemplate = true;

        this.route2.params.subscribe(data => {
            const resulID = data['hash'];
            if (resulID) {
                this.model.user_id = resulID;
            }
        });
    }

    nextSection(_section) {
        if (_section < this.section) {
            this.section = _section;
            return;
        }

        switch (_section) {
            case 1: // address
                if (this.onValidateRegister()) {
                    this.section = _section;
                }
                break;
            case 2:
                if (this.onValidateAddress()) {
                    this.section = _section;
                }
                break;
            default:
                if (this.onValidate()) {
                    this.section = _section;
                }
                break;
        }

    }

    onValidateRegister() {
        if (isNullOrUndefined(this.model.social_reason) || this.model.social_reason === '') {
            super.showMessage('Atenção', 'Preencha a razão social.', 'info');
            return false;
        }
        if (isNullOrUndefined(this.model.cnpj) || this.model.cnpj === '') {
            super.showMessage('Atenção', 'Preencha o CNPJ.', 'info');
            return false;
        }
        if (isNullOrUndefined(this.model.category_id) || this.model.category_id === '') {
            super.showMessage('Atenção', 'Selecione uma especialidade.', 'info');
            return false;
        }
        if (isNullOrUndefined(this.model.email) || this.model.email === '') {
            super.showMessage('Atenção', 'Preencha o e-mail.', 'info');
            return false;
        }
        if (isNullOrUndefined(this.model.ddd) || this.model.ddd === '') {
            super.showMessage('Atenção', 'Preencha o DDD.', 'info');
            return false;
        }
        if (isNullOrUndefined(this.model.phone) || this.model.phone === '') {
            super.showMessage('Atenção', 'Preencha o telefone.', 'info');
            return false;
        }
        if (isNullOrUndefined(this.model.ddd2) || this.model.ddd2 === '') {
            super.showMessage('Atenção', 'Preencha o DDD.', 'info');
            return false;
        }
        if (isNullOrUndefined(this.model.phone2) || this.model.phone2 === '') {
            super.showMessage('Atenção', 'Preencha o telefone.', 'info');
            return false;
        }
        return true;
    }

    onValidateAddress() {
        if (isNullOrUndefined(this.model.city_id) || this.model.city_id === '') {
            super.showMessage('Atenção', 'Selecione uma cidade.', 'info');
            return false;
        }
        if (isNullOrUndefined(this.model.state_id) || this.model.state_id === '') {
            super.showMessage('Atenção', 'Selecione o estado.', 'info');
            return false;
        }
        if (isNullOrUndefined(this.model.postal_code) || this.model.postal_code === '') {
            super.showMessage('Atenção', 'Preencha o CEP.', 'info');
            return false;
        }

        if (isNullOrUndefined(this.model.address) || this.model.address === '') {
            super.showMessage('Atenção', 'Preencha o endereço.', 'info');
            return false;
        }
        if (isNullOrUndefined(this.model.number) || this.model.number === '') {
            super.showMessage('Atenção', 'Preencha o numero do endereço.', 'info');
            return false;
        }
        if (isNullOrUndefined(this.model.district) || this.model.district === '') {
            super.showMessage('Atenção', 'Preencha o bairro.', 'info');
            return false;
        }
        return true;
    }

    onValidate() {

        if (isNullOrUndefined(this.model.contact_name) || this.model.contact_name === '') {
            super.showMessage('Atenção', 'Preencha a razão social.', 'info');
            return false;
        }
        if (isNullOrUndefined(this.model.contact_email) || this.model.contact_email === '') {
            super.showMessage('Atenção', 'Preencha o e-mail.', 'info');
            return false;
        }
        if (isNullOrUndefined(this.model.contact_ddd) || this.model.contact_ddd === '') {
            super.showMessage('Atenção', 'Preencha o DDD.', 'info');
            return false;
        }
        if (isNullOrUndefined(this.model.contact_phone) || this.model.contact_phone === '') {
            super.showMessage('Atenção', 'Preencha o telefone.', 'info');
            return false;
        }

        this.loading.show();
        this.service.register(this.model).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    localStorage.setItem('token', data['token']);
                    this.modelUser = data['user'] as User;
                    super.setUser(this.modelUser);
                    this.router.navigate(['/cadastro-email']);
                }

            },
            error => super.onError(error));

    }

    onSave() {
        if (isNullOrUndefined(this.model.password) || this.model.password === '') {
            super.showMessage('Atenção', 'Preencha a senha.', 'info');
            return true;
        }
        if (isNullOrUndefined(this.password_confirm)) {
            super.showMessage('Atenção', 'Preencha a confirmação da senha', 'info');
            return true;
        }
        if (this.model.password !== this.password_confirm) {
            super.showMessage('Atenção', 'Confirmação de senha inválida', 'info');
            return true;
        }
        this.validTemplate = false;
    }

    getCategory() {
        this.categoryservice.getCategoryEstablshimentRegister().subscribe(
            data => {
                this.listCategory = data as Category[];
            },
            error => super.onError(error));
    }

    getState() {
        this.addressservice.getState().subscribe(
            data => {
                this.listStates = data as State[];
            },
            error => super.onError(error));
    }

    onChangeState(event) {
        this.getCity(event);
    }

    // GET CITY
    getCity(state: string) {
        this.addressservice.getCity(state).subscribe(
            data => {
                this.listCity = data as City[];
            },
            error => super.onError(error));
    }

}
