export class Register {

    id: string;
    social_reason: string;
    email: string;
    contact_name: string;
    contact_email: string;
    cnpj: string;
    contact_ddd: string;
    contact_phone: string;
    ddd: string;
    phone: string;
    ddd2: string;
    phone2: string;
    address: string;
    number: string;
    district: string;
    city_id: string;
    state_id: string;
    reference: string;
    complement: string;
    postal_code: string;
    hash: string;
    category_id: string;
    user_id: string;
    password: string;

    constructor() {

    }

}