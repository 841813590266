import {EventEmitter, Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {Http} from '@angular/http';
import {Order} from '../model/order';
import {HttpClient} from '@angular/common/http';

/**
 * Created by igor on 18/07/17.
 */
@Injectable()
export class PushService extends ServiceBase {

  lastitemorder = 0;
  lastitemcomments = 0;

  eventNewOrder: EventEmitter<number> = new EventEmitter();

  eventNewComment: EventEmitter<number> = new EventEmitter();

  constructor(http: HttpClient) {
    super(http);
    // console.log('START PUSH SERVICE');
    // this.onThreadList();
  }

  onThreadList() {
    const self = this;
    setTimeout(function () {
      if (self.lastitemorder !== 0) {
        console.log('CALL LIST');
        // self.getLastOrder();
      }
    }, 10000);

    setTimeout(function () {
      if (self.lastitemcomments !== 0) {
        console.log('CALL LIST');
        // self.getLastComments();
      }
    }, 10000);

  }

  callFunction() {
    // this.getLastOrder();
    // this.getLastComments();
   // this.onThreadList();
  }

  getLastOrder() {
    const url = `${this.BASE_URL}/api/establishment/order/lastorders`;
    this.http.post(url, {last_item: this.lastitemorder})
      .subscribe(data => {
        this.onThreadList();
        if (data['count'] !== 0) {
          this.lastitemorder = data['last_item'];
          this.sendPushNewOrder(data);
        }
      });
  }

  getLastComments() {
    const url = `${this.BASE_URL}/api/establishment/lastcomments`;
    this.http.post(url, {last_item: this.lastitemcomments})
      .subscribe(data => {
        const count = data['count'];
        if (data['count'] !== 0) {
          this.lastitemcomments = data['last_item'];
          this.sendPushNewComment(data);
        }
      });
  }

  sendPushNewOrder(data: any) {
    this.eventNewOrder.emit(data);
  }

  sendPushNewComment(data: any) {
    this.eventNewComment.emit(data);
  }

}
