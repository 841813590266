import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {AppComponent} from './app.component';
import {AppRouteModule} from './app.route';
import {MainComponent} from './view/main/main.component';
import {HomeComponent} from './view/home/home.component';
import {AuthGuard} from './service/auth-guard.service';
import {LoginComponent} from './view/login/login.component';
import {EstablishmentComponent} from './view/establishment/establishment.component';
import {ProductDetailComponent} from './view/product-detail/product-detail.component';
import {UserComponent} from './view/establishment-user/user.component';
import {PaymentComponent} from './view/establishment-payment/payment.component';
import {AttendenceComponent} from './view/establishment-attendence/attendence.component';
import {UserService} from './service/user.service';
import {NumberFormatterDirective} from './directive/number-formatter.directive';
import {PaginationComponent} from './view/ui/pagination/pagination/pagination.component';
import {PaginatorPipe} from './base/paginator.pipe';
import {CommentComponent} from './view/comment/comment.component';
import {ExtractComponent} from './view/extract/extract.component';
import {StatisticComponent} from './view/statistic/statistic.component';
import {EstablishmentCategoryComponent} from './view/establishment-category/establishment-category.component';
import {EstablishmentOrderComponent} from './view/establishment-order/establishment-order.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HeaderHttpInterceptor} from './base/header-http-interceptor';
import {OrderComponent} from './view/order/order.component';
import {TipsComponent} from './view/tips/tips.component';
import {PlanComponent} from './view/plan/plan.component';
import {MenuComponent} from './view/menu/menu.component';
import {ProductSizeComponent} from './view/product-size/product-size.component';
import {MainRegisterComponent} from './view/main-register/main-register.component';
import {WelcomeComponent} from './view/welcome/welcome.component';
import {RegisterComponent} from './view/register/register.component';
import {RegisterEmailComponent} from './view/register-email/register-email.component';
import {PaymentFormComponent} from './view/payment-form/payment-form.component';
import {TicketComponent} from './view/ticket/ticket.component';
import {CreditCardComponent} from './view/credit-card/credit-card.component';
import {CredentialComponent} from './view/credential/credential.component';
import {MapComponent} from './view/establishment-map/map.component';
import {PointReplacerMoneyPipe} from './directive/moneyFormatter.pipe';
import {ColorPickerModule} from 'ngx-color-picker';
import {GraphicOrderComponent} from './view/graphic-order/graphic-order.component';
import {TipsMarketingComponent} from './view/tips-marketing/tips-marketing.component';
import {TipsPhotoComponent} from './view/tips-photo/tips-photo.component';
import {TipsFinancialComponent} from './view/tips-financial/tips-financial.component';
import {TipsDiscontComponent} from './view/tips-discont/tips-discont.component';
import {GraphDeliveryComponent} from './view/graph-delivery/graph-delivery.component';
import {GraphRevenuesComponent} from './view/graph-revenues/graph-revenues.component';
import {GraphicPerformanceComponent} from './view/graphic-performance/graphic-performance.component';
import {ComplementComponent} from './view/product-complement/complement.component';
import {PrinterComponent} from './view/establishment-printer/printer.component';
import { TermComponent } from './view/term-establishment/term.component';
import { TermAppComponent } from './view/term-app/term-app.component';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { CategoryComponent } from './view/category/category.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {SweetAlert2Module} from '@toverux/ngx-sweetalert2';
import { OrderDetailComponent } from './view/order-detail/order-detail.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {ImageCropperModule} from 'ngx-image-cropper';
import { CuponComponent } from './view/cupon/cupon.component';
import { CreatePromocodeComponent } from './view/cupon/create-promocode/create-promocode.component';

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        HomeComponent,
        LoginComponent,
        EstablishmentComponent,
        ProductDetailComponent,
        UserComponent,
        PaymentComponent,
        AttendenceComponent,
        NumberFormatterDirective,
        PaginationComponent,
        PaginatorPipe,
        CommentComponent,
        ExtractComponent,
        StatisticComponent,
        EstablishmentCategoryComponent,
        EstablishmentOrderComponent,
        OrderComponent,
        TipsComponent,
        PlanComponent,
        MenuComponent,
        ProductSizeComponent,
        MainRegisterComponent,
        WelcomeComponent,
        RegisterComponent,
        RegisterEmailComponent,
        PaymentFormComponent,
        TicketComponent,
        CreditCardComponent,
        CredentialComponent,
        MapComponent,
        PointReplacerMoneyPipe,
        GraphicOrderComponent,
        TipsMarketingComponent,
        TipsPhotoComponent,
        TipsFinancialComponent,
        TipsDiscontComponent,
        GraphDeliveryComponent,
        GraphRevenuesComponent,
        GraphicPerformanceComponent,
        ComplementComponent,
        PrinterComponent,
        TermComponent,
        TermAppComponent,
        CategoryComponent,
        OrderDetailComponent,
        CuponComponent,
        CreatePromocodeComponent
    ],
    imports: [
        BrowserModule,
        AppRouteModule,
        FormsModule,
        HttpClientModule,
        ColorPickerModule,
        UiSwitchModule,
        SweetAlert2Module.forRoot(),
        NgxSpinnerModule,
        AutocompleteLibModule,
        ImageCropperModule
    ],
    providers: [
        AuthGuard,
        UserService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderHttpInterceptor,
            multi: true
        }],
    bootstrap: [AppComponent]
})
export class AppModule {
}
