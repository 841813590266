/**
 * Created by Igor on 09/05/2017.
 */
export class Payment {

  id: number;
  description: string;
  payment_type: PaymentType;
  total: number;

  constructor() {
    this.total = 0;
  }

}

export class PaymentType {

    description: string;
    icon: string;
    id: string;

    constructor() {

    }

}