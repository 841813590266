import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {Http} from '@angular/http';
import {Order} from '../model/order';
import {HttpClient} from '@angular/common/http';

/**
 * Created by Igor on 10/05/2017.
 */
@Injectable()
export class OrderService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getOrder(data) {
        const url = `${this.BASE_URL}/api/establishment/order/list`;
        return this.http.post(url, data);
    }

    getOrderById(id: string) {
        const url = `${this.BASE_URL}/api/establishment/order/byid/${id}`;
        return this.http.get(url);
    }

    updateOrder(model: Order) {
        const url = `${this.BASE_URL}/api/establishment/order/update`;
        return this.http.post(url, model);
    }

    printOrder(id) {
        const url = `${this.BASE_URL}/api/printer/print/${id}`;
        return this.http.post(url, null);
    }

    getOrderExtract(data) {
        const url = `${this.BASE_URL}/api/establishment/plan/extract`;
        return this.http.post(url, data);
    }

    getChartOrders(data) {
        const url = `${this.BASE_URL}/api/establishment/getchartorders`;
        return this.http.post(url, data);
    }

    cancelOptionsList() {
        const url = `${this.BASE_URL}/api/cancel/option/list`;
        return this.http.get(url);
    }

    sendToBag(id: string) {
        const url = `${this.BASE_URL}/api/establishment/bag/send/${id}`;
        return this.http.post(url, null);
    }
}
