import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {User} from '../../model/user';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-credential',
  templateUrl: './credential.component.html',
  styleUrls: ['./credential.component.css']
})
export class CredentialComponent extends BaseComponent implements OnInit {

  model: User = new User();
  password_confirm: string;

  constructor(public loading: NgxSpinnerService
              , public router: Router) {
      super(router, loading);
  }

  ngOnInit() {
    this.model = new User();
  }

  onSave() {

  }

}
