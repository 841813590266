import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import '../../util/maps' ;
import {MapService} from '../../service/map.service';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {Delivery, Map} from '../../model/map';
import {ResponseServer} from '../../model/response_server';
import {NgxSpinnerService} from 'ngx-spinner';


@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.css'],
    providers: [MapService]
})
export class MapComponent extends BaseComponent implements OnInit {

    model: Map = new Map();
    drawing = false;
    mapInited = false;
    drawResult = '';
    color: any;

    delivery = new Delivery();

    constructor(public router: Router,
                public service: MapService,
                public loading: NgxSpinnerService) {
        super(router, loading);
    }

    ngOnInit(): void {
        this.getMapDetails();
    }

    getMapDetails() {
        this.service.getMapDetails().subscribe(
            data => {
                this.model = data as Map;

                MapsG.initMap(this.model.lat, this.model.lng);

                const parent = this;

                this.sleep(1000).then(function () {

                    MapsG.markation(parent.model.lat, parent.model.lng);

                    for (const delivery of parent.model.deliveries) {
                        MapsG.addPolygon(delivery.shape,
                            delivery.color,
                            delivery.id,
                            delivery.value,
                            delivery.minimum_value,
                            delivery.name, function (e) {
                                const d = parent.model.deliveries.find(de => de.id === e);
                                parent.onEdit(d, true);
                            });
                    }
                });
            },
            error => super.onError(error));
        this.mapInited = true;
    }

    drawMap(remove = false) {
        if (!this.drawing || remove === true) {
            this.drawing = true;
            const parent = this;
            MapsG.drawable(this.model.lat, this.model.lng, function (event) {
                parent.drawResult = event.toString();
            });
            this.delivery.color = '#' + (Math.random() * 0xFFFFFF << 0).toString(16);
            this.colorChanged();
            this.sleep(1000).then(function () {
                MapsG.markation(parent.model.lat, parent.model.lng);
                for (const delivery of parent.model.deliveries) {
                    MapsG.addPolygon(delivery.shape,
                        delivery.color,
                        delivery.id,
                        delivery.value,
                        delivery.minimum_value,
                        delivery.name, function (e) {
                            const d = parent.model.deliveries.find(de => de.id === e);
                            parent.onEdit(d, true);
                        });
                }
            });
        }
        this.cleanDraw();
    }

    cancel() {
        this.drawing = false;
        this.getMapDetails();
    }

    openNewDelivery() {
        this.drawing = false;
        this.cleanDraw();

        const tab0 = document.getElementById('tab_1');
        tab0.classList.remove('in');
        tab0.classList.remove('active');
        tab0.classList.remove('show');
        const tab1 = document.getElementById('tab_0');
        tab1.classList.add('in');
        tab1.classList.add('active');
        tab1.classList.add('show');

        const parent = this;
        this.sleep(500).then(function () {
            parent.drawMap();
        });
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    cleanDraw() {
        this.drawResult = '';
    }

    save() {
        this.delivery.drawresult = this.drawResult;
        this.loading.show();
        this.service.addShape(this.delivery).subscribe(
            data => {
                const result = data as ResponseServer;
                this.loading.hide();
                this.drawing = false;
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.getMapDetails();
                    super.onCloseModal('#delivery');
                }
            },
            error => super.onError(error));
    }

    remove(id: string) {
        this.loading.show();
        this.service.deleteShape(id).subscribe(
            data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.getMapDetails();
                }
            },
            error => super.onError(error));
    }

    onRemove(hash: string) {
        const self = this;
        super.showConfirmMessage(
            'Atenção',
            'Tem certeza que deseja remover esse delivery?',
            'warning', () => {
                self.remove(hash);
            });
    }

    colorChanged() {
        MapsG.changeColor(this.delivery.color);
    }

    editComplete(delivery: Delivery) {
        this.loading.show();
        delivery.name = delivery.edit_name;
        delivery.value = delivery.edit_value;
        delivery.minimum_value = delivery.edit_minimum_value;
        this.service.updateDelivery(delivery).subscribe(
            data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.getMapDetails();
                }
            },
            error => super.onError(error));
    }

    onEdit(del: Delivery, edit: boolean) {
        del.edit_name = del.name;
        del.edit_value = del.value;
        del.edit_minimum_value = del.minimum_value;
        del.editing = edit;
        document.getElementById('map_tab').classList.remove('active');
        document.getElementById('details_tab').classList.add('active');
        const tab0 = document.getElementById('tab_0');
        tab0.classList.remove('in');
        tab0.classList.remove('active');
        tab0.classList.remove('show');
        const tab1 = document.getElementById('tab_1');
        tab1.classList.add('in');
        tab1.classList.add('active');
        tab1.classList.add('show');
    }
}
