import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {HttpClient} from '@angular/common/http';

/**
 * Created by Igor on 10/05/2017.
 */
@Injectable()
export class PlanService extends ServiceBase {
    constructor(http: HttpClient) {
        super(http);
    }

    getPlan() {
        const url = `${this.BASE_URL}/api/establishment/plan/list`;
        return this.http.get(url);
    }

    changePlan(id) {
        const url = `${this.BASE_URL}/api/establishment/plan/change`;
        const data = {'plan_id': id};
        return this.http.post(url, data);
    }

    chooseBoleto(data) {
        const url = `${this.BASE_URL}/api/establishment/plan/boleto/`;
        return this.http.post(url, data);
    }


    chooseCard(data) {
        const url = `${this.BASE_URL}/api/establishment/plan/credit-card`;
        return this.http.post(url, data);
    }

}