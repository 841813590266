import {OrderComplements} from "./order_complements";

export class OrderItem {

    id: number;
    name: string;
    price: string;
    total: string;
    amount: number;
    item_id: string;
    observation: string;
    price_total: number;
    size: string;
    complements: OrderComplements[];

    constructor() {
        this.complements = [];
    }
}


