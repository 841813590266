import {ServiceBase} from './service-base';
import {Establishment} from '../model/establishment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Register} from '../model/register';
import {Settings} from '../settings';

@Injectable()
export class EstablishmentService extends ServiceBase {
    constructor(http: HttpClient) {
        super(http);
    }

    getEstablishment() {
        const url = `${this.BASE_URL}/api/establishment/data/profile`;
        return this.http.get(url);
    }

    getEstablishmentConfig() {
        const url = `${this.BASE_URL}/api/establishment/config`;
        return this.http.get(url);
    }

    create(model: Establishment) {
        const url = `${this.BASE_URL}/api/establishment/add/`;
        return this.http.post(url, model);
    }

    update(model: Establishment) {
        const url = `${this.BASE_URL}/api/establishment/update/`;
        return this.http.post(url, model);
    }

    register(model: Register) {
        const url = `${this.BASE_URL}/api/register/establishment`;
        return this.http.post(url, model);
    }

    generateShareLink(id: string) {
        const url = Settings.FIREBASE_SHARE_LINK_URL;
        return this.http.post(url,
            '{ "dynamicLinkInfo": {' +
            '"domainUriPrefix":"' + Settings.DOMAIN_URI_PREFIX + '",' +
            '"link":"' + Settings.LINK + id + '",' +
            '"androidInfo": { "androidPackageName": "' + Settings.ANDROID_PACKAGE_NAME + '" },' +
            '"iosInfo": { "iosBundleId": "' + Settings.IOS_BUNDLE_ID + '", "iosAppStoreId": "'
            + Settings.IOS_APP_STORE_ID + '" }' +
            '}}'
        );
    }


    /*update(model: Establishment, file) {
        const url = `${this.BASE_URL}/api/establishment/update`;
        return Observable.create(observer => {

            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();

            formData.append('social_reason', model.social_reason);
            formData.append('id', model.id.toString());
            formData.append('ddd', model.ddd);
            formData.append('phone', model.phone);
            formData.append('email', model.email);
            formData.append('information', model.information);

            console.log(file);
            if (file != null) {
                formData.append('file', file, file.name);
            }

            console.log(formData);

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.upload.onprogress = (event) => {
                const progress = Math.round(event.loaded / event.total * 100);
            };

            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.getToken());
            xhr.send(formData);
        });
    }*/

    /*upload(id, action, file: File): Observable<ResponseServer> {
      const url = `${this.BASE_URL}/api/establishment/upload`;
      return Observable.create(observer => {

        let formData: FormData = new FormData(),
          xhr: XMLHttpRequest = new XMLHttpRequest();

        formData.append("id", id);
        formData.append("action", action);
        formData.append("file", file, file.name)changePassword(update: string) {
        this.loading.show();
        console.log();
        this.serviceestablishment.updateStatus(this.model.establishment.id, update).subscribe(data => {
            const result = data as ResponseServer;
            this.loading.hide();
            this.model.establishment.status = data['status'];
            super.setUser(this.model);
            this.changeStatusClass(data['status']);
            if (update) {
                super.showMessage('Sucesso', result.message, 'OK', '', 'success', null);
            }
        });
        this.loading.hide();
    };

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              observer.next(JSON.parse(xhr.response));
              observer.complete();
            } else {
              observer.error(xhr.response);
            }
          }
        };

        xhr.upload.onprogress = (event) => {
          const progress = Math.round(event.loaded / event.total * 100);
        };

        xhr.open('POST', url, true);
        xhr.setRequestHeader("Authorization", 'Bearer ' + this.getToken());
        xhr.send(formData);
      });
    }*/

    updateStatus(id: string, update: boolean) {
        const url = `${this.BASE_URL}/api/establishment/update/status`;
        const data = {id: id, update: update};
        return this.http.post(url, data);
    }
}
