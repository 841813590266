import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {PaymentService} from '../../service/payment.service';
import {Payment} from '../../model/payment';
import {ResponseServer} from '../../model/response_server';
import {isNullOrUndefined} from 'util';
import {NgxSpinnerService} from 'ngx-spinner';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.css'],
    providers: [PaymentService]
})
export class PaymentComponent extends BaseComponent implements OnInit {

    listPayment: Payment[] = [];
    listPaymentEstablishment: Payment[] = [];
    model: Payment = new Payment();
    modelSend = {
        payment_type_id: ''
    };
    pager: any = {};
    isOnlineEnabled: boolean;
    isOnlineConfigured: boolean;


    constructor(public router: Router,
                public service: PaymentService,
                public loading: NgxSpinnerService) {
        super(router, loading);
    }

    ngOnInit() {
        this.getList();
        this.getListPayments();
    }

    getList() {
        this.loading.show();
        this.getAllPayment();
    }

    getAllPayment() {
        this.service.getPayment().subscribe(
            data => {
                this.loading.hide();
                this.listPayment = data['payment_types'] as Payment[];
                this.isOnlineEnabled = data['is_online_enabled'] as boolean;
                this.isOnlineConfigured = data['is_online_configured'] as boolean;
                this.getListPayments();
            },
            error => super.onError(error));
    }


    enableOnline() {
        const user = super.getUser();
        if (!this.isOnlineConfigured) {
            window.location.href =
                (environment.checkout_url_activate + '?d=register&id=' + user.establishment.id + '&t=' + localStorage.getItem('token'));
        } else {
            this.loading.show();
            this.service.enableOnlinePayment(user.establishment.id).subscribe(
                data => {
                    this.getAllPayment();
                },
                error => super.onError(error));
        }
    }

    disableOnline() {
        this.loading.show();
        this.service.disableOnlinePayment().subscribe(
            data => {
                this.getAllPayment();
            },
            error => super.onError(error));
    }

    configureOnlinePayment() {
        const user = super.getUser();
        window.open((environment.checkout_url_activate + '?d=payment-ways&id='
            + user.establishment.id + '&t=' + localStorage.getItem('token')));
    }

    withdrawOnlinePayment() {
        const user = super.getUser();
        window.open((environment.checkout_url_activate + '?d=balance&id='
            + user.establishment.id + '&t=' + localStorage.getItem('token')));
    }

    getListPayments() {
        this.loading.show();
        this.service.getPaymentEstablishment().subscribe(
            data => {
                this.loading.hide();
                this.listPaymentEstablishment = data as Payment[];
                this.setPage(1);
            },
            error => super.onError(error));
    }

    onSubmitSave() {
        if (typeof this.modelSend.payment_type_id === 'undefined') {
            super.showMessage('Atenção', 'Selecione a Forma de Pagamento', 'info');
            return;
        }

        this.loading.show();

        this.service.create(this.modelSend).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.model = new Payment();
                    this.getAllPayment();
                    this.getListPayments();
                }
            },
            error => super.onError(error));
    }

    remove(id: string) {
        this.modelSend.payment_type_id = id;
        this.service.delete(this.modelSend).subscribe(data => {
                const result = data as ResponseServer;
                this.loading.hide();
                if (result.error) {
                    super.showMessage('Atenção', result.message, 'info');
                } else {
                    this.getAllPayment();
                    this.getListPayments();
                }
            },
            error => super.onError(error));
    }

    onRemove(id: string) {
        const self = this;
        super.showConfirmMessage('Atenção',
            'Tem certeza que deseja remover essa forma de pagamento?', 'warning', () => {
                self.remove(id);
            });
    }

    setPage(page: number) {
        this.pager = this.getPager(this.listPaymentEstablishment.length, page, 5);
    }
}
