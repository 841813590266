export class ProductSize {

    id: string;
    initials: string;
    description: string;
    establishment_product_id: string;
    price: number;
    discount: number;
    discountMoney: number;
    order: number;

    constructor() {
        this.discount = 0;
        this.order = 0;
    }

}
