import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {BaseComponent} from '../../base/base.component';
import {OrderService} from '../../service/order.service';
import '../../util/charts';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-statistic',
    templateUrl: './statistic.component.html',
    styleUrls: ['./statistic.component.css'],
    providers: [OrderService]
})
export class StatisticComponent extends BaseComponent implements OnInit {

    flag: number;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public orderservice: OrderService) {
        super(router, loading);
    }

    ngOnInit() {
        this.flag = 1;
    }

    changeTab(tab) {
        this.flag = tab;
    }

}
