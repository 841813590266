import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {Http} from '@angular/http';
import {Product} from '../model/product';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

/**
 * Created by Igor on 10/05/2017.
 */
@Injectable()
export class ProductService extends ServiceBase {
    constructor(http: HttpClient) {
        super(http);
    }

    public productDataSource = new BehaviorSubject<Boolean>(false);
    public productData = this.productDataSource.asObservable();


    getProducts(data) {
        const url = `${this.BASE_URL}/api/establishment/product/list`;
        return this.http.post(url, data);
    }

    getProduct(hash: string) {
        const url = `${this.BASE_URL}/api/establishment/product/get/${hash}`;
        return this.http.get(url);
    }

    duplicateProduct(hash: string) {
        const url = `${this.BASE_URL}/api/establishment/product/duplicate/${hash}`;
        return this.http.get(url);
    }

    create(model: Product) {
        const url = `${this.BASE_URL}/api/establishment/product/add`;
        return this.http.post(url, model);
    }

    update(model: Product) {
        const url = `${this.BASE_URL}/api/establishment/product/update`;
        return this.http.post(url, model);
    }

    delete(hash: string) {
        const url = `${this.BASE_URL}/api/establishment/product/remove/${hash}`;
        return this.http.delete(url);
    }


    /*crop(id: number, name: string, x: number, y: number) {
     const url = `${this.BASE_URL}/api/establishment/product/upload?XDEBUG_SESSION_START=PHPSTORM`;
     let data = {id: id, action: 1, photo: name, pos_x: x, pos_y: y};
     return this.http
     .post(url, data, this.header())
     .map(this.extractData)
     .catch(this.onError);
     }*/

    getProductOrder(hash: string) {
        const url = `${this.BASE_URL}/api/establishment/product/order/get/${hash}`;
        return this.http.get(url);
    }

    status(hash: string) {
        const url = `${this.BASE_URL}/api/establishment/product/approve/${hash}`;
        return this.http.get(url);
    }

}
