import {ServiceBase} from './service-base';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Promocode} from "../model/promocode";

@Injectable({
    providedIn: 'root'
})
export class PromocodeService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getPromocode(data) {
        const url = `${this.BASE_URL}/api/establishment/promocode/list`;
        return this.http.post(url, data);
    }

    getPromoCodeById(id: string) {
        const url = `${this.BASE_URL}/api/establishment/promocode/get/${id}`;
        return this.http.get(url);
    }

    send(model: Promocode) {
        const url = `${this.BASE_URL}/api/establishment/promocode/add`;
        return this.http.post(url, model);
    }

    delete(hash: string) {
        const url = `${this.BASE_URL}/api/establishment/promocode/delete/${hash}`;
        return this.http.delete(url);
    }
}
