import {Establishment} from "./establishment";

export class Printer {

    id: string;
    name: string;
    //driver: string; // 'bematech','daruma', 'windows'
    //type: string; //'kitchen','balcony', 'both'
    pin: string;
    osversion: string; //'active' , 'inactive'
    status: string; //'active' , 'inactive'
    client_version: string;
    model: string;
    establishment_id: string;
    online: number;
    created_at: string;
    establishment: Establishment;
    port: string;
    updated_at: string;

    constructor(){}
}