import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-tips-marketing',
    templateUrl: './tips-marketing.component.html',
    styleUrls: ['./tips-marketing.component.css']
})
export class TipsMarketingComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
