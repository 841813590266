import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tips-discont',
  templateUrl: './tips-discont.component.html',
  styleUrls: ['./tips-discont.component.css']
})
export class TipsDiscontComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
